/* eslint-disable import/prefer-default-export */
import Link from '@material-ui/core/Link';
import styled from 'styled-components';

export const themeColors = {
  mainRed: '#f53d23',
  mainText: '#575756',
  mainYellow: '#f9b000',
};

export const Container = styled.div`
  margin: 0 auto;
  width: 1110px;

  @media (max-width: 1200px) {
    max-width: 930px;
    box-sizing: border-box;
  }

  @media (max-width: 900px) {
    max-width: 90%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 420px) {
    max-width: 100%;
  }
`;

export const Section = styled.section`
  margin: 3rem 0 5rem 0;
  box-sizing: border-box;
  max-width: 100%;
`;

export const ImagemResponsive = styled.img`
  object-fit: fill;
  max-width: 100%;
  min-height: 100%;

  @media (max-width: 1300px) {
    object-fit: contain;
    /* height: 7rem; */
    height: auto;
    min-height: 100%;
  }

  @media (max-width: 420px) {
    object-fit: cover;
    min-height: 100%;
    width: 100%;
  }
`;

export const ImagemResponsiveBanner = styled.img`
  object-fit: contain;
  max-width: 100%;
  height: 100%;
`;

export const Title = styled.h2`
  color: ${themeColors.mainRed};
  font-size: xx-large;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 2rem 0;

  span {
    font-weight: bold;
  }
`;

export const DivImg = styled.div`
  max-width: 100%;
  height: auto;
`;

export const TitleWithImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 6rem;
    height: auto;
  }
`;

export const AcessoRapido = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  @media (max-width: 1200px) {
  }

  @media (max-width: 900px) {
    gap: 1rem;
  }

  @media (max-width: 600px) {
    display: block;
    gap: 0.4rem;
  }

  @media (max-width: 420px) {
    display: block;
  }
`;

export const ImgIcone = styled.img`
  object-fit: contain;
  max-width: 80%;
  transition: all 0.2s ease-in-out;
`;

export const SectionVideo = styled.section`
  margin: 4rem 0 4rem 0;
  height: 520px;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 900px) {
    height: 400px;
    width: 80%;
  }

  @media (max-width: 420px) {
    height: 300px;
    width: 100%;
  }
`;

export const LinkAcessoRapido = styled.a`
  padding-top: 1rem;
  /* max-width: 27%; */
  flex: 1 1 0px;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: ${themeColors.mainText};
  text-align: center;
  align-items: center;
  text-decoration: none;
  transition: all 1ms ease-in-out;
  @media (max-width: 600px) {
    text-align: left;
    /* align-items: flex-start; */
    gap: 0.7rem;
  }

  @media (max-width: 420px) {
    text-align: left;
    /* align-items: flex-start; */
    gap: 0.7rem;
  }

  &:hover {
    ${ImgIcone} {
      transform: scale(120%);
    }
  }

  div {
    h3 {
      padding: 0;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 0.7rem;
      word-wrap: break-word;
      line-break: normal;
    }
  }
`;

export const IconeAcessoRapido = styled.div`
  background-color: ${themeColors.mainRed};
  min-width: 7rem;
  padding: 20px;
  height: 6rem;
  border-radius: 26px 0 26px 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    min-width: 85px;
    height: 5rem;
  }

  @media (max-width: 600px) {
    max-width: 3rem;
    width: 2rem;
    max-height: 4.5rem;
    border-radius: 15px 0 15px 15px;
    padding: 15px;
  }

  @media (max-width: 420px) {
    max-width: 3rem;
    width: 2rem;
    max-height: 4.5rem;
    border-radius: 15px 0 15px 15px;
    padding: 15px;
  }
`;

export const TextoDestacado = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: ${themeColors.mainText};
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1rem;
`;

export const TextPadrao = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  text-align: center;
`;

export const GridLojistica = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 6rem;
  margin-top: 3rem;

  @media (max-width: 420px) {
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    max-width: 100%;
  }
`;

export const IconeLogistica = styled.div`
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 1.3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: ${themeColors.mainText};
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  @media (max-width: 420px) {
    min-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;

    img {
      width: 5rem;
      height: 4rem;
      object-fit: scale-down;
    }
  }
`;

export const RedeSocial = styled(Link)`
  align-items: center;
  display: flex;

  > svg {
    margin-right: 5px;
  }
`;

export const TitleContato = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: ${themeColors.mainRed};

  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const ListaRedesSocial = styled.ul`
  text-align: left;
  @media (max-width: 600px) {
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
`;

export const ListaContatos = styled.ul`
  text-align: left;
  @media (max-width: 600px) {
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
