import {
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { CloseOutlined, StorefrontOutlined } from '@material-ui/icons';
import { format } from 'date-fns';

import iconDetalhes from '../assets/icones/detalhes.png';
import { toReal } from '../helpers/utils';
import Flex from './flex';

interface ParcelaDetalhesProps {
  idVenda: string;
  idCarne: string;
  lojaNome: string | null;
  numeroParcela: number;
  dataVenda: Date;
  dataVencimento: Date;
  valorOriginal: number;
  valorJuros: number;
  valorDescontado: number;
  valorTotal: number;
  onClose: () => void;
}

const ParcelaDetalhes = ({
  dataVenda,
  dataVencimento,
  idCarne,
  idVenda,
  lojaNome,
  numeroParcela,
  valorDescontado,
  valorJuros,
  valorOriginal,
  valorTotal,
  onClose,
}: ParcelaDetalhesProps): JSX.Element => (
  <div className='flex flex-col p-2' style={{ background: 'rgba(244,244,244,1)' }}>
    <div className="flex  bg-white flex-col rounded-sm ]">
      <div className="flex justify-between w-full">
        <Typography
          className="flex text-black p-2 gap-2 rounded w-full items-center"
          variant="h6"
        >
          <img alt="Imagem Detalhes" className="w-7 h-7" src={iconDetalhes} />
          Detalhes da parcela
        </Typography>
        <button
          aria-label='Fechar'
          className='pr-2'
          onClick={onClose}
          style={{ border: 'none', background: 'none' }}
          type="button"
        >
          <CloseOutlined />
        </button>
      </div>
      <div
        style={{
          padding: '1rem',
          paddingBottom: '.75rem',
          marginBottom: '1rem',
        }}
      >
        <div style={{ color: '#6d6c6c' }}>
          <Typography style={{ fontWeight: 500 }} variant="body1">
            Parc {numeroParcela} da venda {idVenda} de{' '}
            {format(dataVenda, 'dd/MM/yyyy')}
          </Typography>
          <Typography
            component="div"
            gutterBottom
            style={{ alignItems: 'end', display: 'flex', fontSize: '1rem' }}
            variant="subtitle2"
          >
            <StorefrontOutlined fontSize="large" />
            LOJA&nbsp;
          </Typography>
          <Typography>{lojaNome}</Typography>
        </div>
      </div>
      <div className='flex flex-col pl-4 pr-4 pb-2'>
        <Typography variant="subtitle2">Número do carnê</Typography>
        <Typography variant="body2">{idCarne}</Typography>

        <Typography variant="subtitle2">Vencimento</Typography>
        <Typography variant="body2">
          {format(dataVencimento, 'dd/MM/yyyy')}
        </Typography>

        <Divider style={{ margin: '.5rem 0' }} />

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Typography>Valor original</Typography>
          </Grid>
          <Grid item>
            <Typography>{toReal(valorOriginal)}</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Typography>Juros</Typography>
          </Grid>
          <Grid item>
            <Typography>{toReal(valorJuros)}</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Typography color="secondary">Desconto (Hoje)</Typography>
          </Grid>
          <Grid item>
            <Typography color="secondary">{toReal(valorDescontado)}</Typography>
          </Grid>
        </Grid>

        <Divider style={{ margin: '.5rem 0' }} />

        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Typography color="primary">Valor total</Typography>
          </Grid>
          <Grid item>
            <Typography color="primary" style={{ fontWeight: 'bold' }}>
              {toReal(valorTotal)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>

    {/* <Typography variant="subtitle2">
      Número da nota fiscal
    </Typography>
    <Typography variant="body2">
      {nfNumero}
    </Typography> */}

    {/* <Button
      color="primary"
      fullWidth
      onClick={onClickVisualizarProdutos}
      startIcon={<ShoppingBasketOutlined />}
      style={{ marginTop: '2rem' }}
      variant="outlined"
    >Visualizar Produtos</Button> */}
  </div>
);

export default ParcelaDetalhes;
