import { ChangeEvent, useCallback } from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';

const CnpjField = ({ onChange, ...restProps }: TextFieldProps): JSX.Element => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length <= 14) {
      // XX.
      value = value.replace(/^(\d{2})(\d)/, '$1.$2');
      // XX.XXX.
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      // XX.XXX.XXX/
      value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
      // XX.XXX.XXX/XXXX-XX
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      value = e.target.value.substr(0, 18);
    }

    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value
        }
      });
    }
  }, [onChange]);

  return (
    <TextField {...restProps} onChange={handleChange} />
  );
};

export default CnpjField;
