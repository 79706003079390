/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-sort-props */
import { useCallback, useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import  { AxiosResponse } from 'axios';

import { getSolicitacoes, minhaConta } from '../../api/lgpd/Lgpd';
import { Carregando } from '../../components';
import { formatarData } from '../../helpers/utils';

interface Solicitacao {
  id: string;
  clientId: string;
  reason: string;
  status: string;
  retificacaoOpcao: string;
  privacyPolicyId: string;
  createdAt: string;
  updatedAt: string;
  files: [
    {
      id: string;
      name: string;
      type: string;
      url: string;
      createdAt: string;
    }
  ];
  receiveInformationBy: string;
}

interface Cadastro {
  id: number;
  email: string;
  cpf: string;
  nomeCompleto: string;
  dataNascimento: string;
  telefonePrincipal: string;
  telefoneSecundario?: string | null;
}

const LgpdInicial = (): any => {
  const [solicitacoes, setSolicitaccao] = useState<any[]>([]);
  const [carregando, setCarregando] = useState(true);
  const [user, setUser] = useState<Cadastro>({} as Cadastro);

  useEffect(() => {
    const findUser = async () => {
      try {
        const responseCliente: AxiosResponse = await minhaConta();
        setUser(responseCliente.data);
      } catch (error) {
        console.log(error);
      }
    };
    findUser();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: AxiosResponse = await getSolicitacoes(user.cpf);
        setSolicitaccao(response.data.requests);
        setCarregando(false);
      } catch (error) {
        console.log(error);
        setCarregando(false);
      }
    };
    fetchData();
  }, [user]);

  const handleClick = useCallback((string) => {
    document.location.href = `/dados-cliente/lgpd-solicitacao-detalhes/${string}/${false}`;
}, []);

  // if (carregando) {
  //   return (
  //     <div className="flex p-11 justify-center items-center w-full mx-auto">
  //       <Carregando />
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col  pt-0  lg:w-[800px] min-h-[300px]">
      <Typography
        className="flex justify-between, items-center w-full pt-4 pb-4 text-[#CA181A]"
        variant="h5"
      >
        <b>Minhas Solicitações</b>
      </Typography>

      <div className="flex flex-col  gap-4 justify-center items-center h-full">
      {carregando && (
        <div className="flex p-11 lg:pl-[50px] justify-center items-center w-full mx-auto">
          <Carregando />
        </div>
      )}
        {solicitacoes.map((el) => (
          <div
            className="flex flex-col items-center w-full p-2 border border-gray-300 rounded shadow hover:shadow-2xl cursor-pointer "
            key={el.id}
            onClick={() => handleClick(el.id)}
          >
            <div className="flex gap-2 items-center w-full  ">
              <span className="font-bold">Solicitação: </span>
              <span className='overflow-hidden overflow-ellipsis whitespace-nowrap'>{el.reason}</span>
            </div>
            <div className="flex gap-2 pt-2 items-center w-full">
              <span className="font-bold">Status: </span>
              <span>{el.status}</span>
            </div>
            <div className="flex pt-1 gap-2 items-center w-full">
              <span className="font-bold">Data: </span>
              <span>{formatarData(el.createdAt)}</span>
            </div>
          </div>
        ))}
        {solicitacoes.length === 0 &&  (
          <div className="flex justify-center items-center pt-6">
            <span className="flex text-lg text-gray-300">
              Não há solicitações
            </span>
          </div>
        )}
      </div>
      <div className="flex mt-auto justify-center items-center text-white pt-6">
        <a
          className="bg-[#CA181A] hover:bg-red-500 lg:w-[400px] w-[200px] p-2 rounded text-center"
          href="/dados-cliente/lgpd-nova-solicitacao"
        >
          {' '}
          Nova Solicitação
        </a>
      </div>
    </div>
  );
};

export default LgpdInicial;
