import { CSSProperties } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';

interface CarregandoProps {
  mensagem?: string;
  style?: CSSProperties;
}

const Carregando = ({ mensagem, style }: CarregandoProps): JSX.Element => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10%',
      ...style
    }}
  >
    <CircularProgress size={70} />
    <Typography component="p" style={{ fontSize: 18 }} variant="subtitle1">
      {mensagem}
    </Typography>
  </div>
);

Carregando.defaultProps = {
  mensagem: 'Carregando',
  style: undefined
}

export default Carregando;
