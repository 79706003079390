/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

import theme from "../../../constants/theme";

export const ParcelaSelecionada = styled.a`
  padding: 0 12px 0 12px;
  position: fixed;
  width: 73%;
  height: 3rem;
  bottom: 4%;
  z-index: 999;
  background-color: ${theme.palette.primary.main};
  border-radius: 8px;
  text-decoration: none;
  color: white;
  display: flex;

  span{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.0rem;
    padding: 0.5rem;
  }
`
export const ParcelaSelecionadaDesktop = styled.a`
  padding: 0 12px 0 12px;
  position: fixed;
  width: 543px;
  height: 3rem;
  bottom: 4%;
  z-index: 999;
  background-color: ${theme.palette.primary.main};
  border-radius: 8px;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;

  span{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.0rem;
    padding: 0.5rem;
  }
`

