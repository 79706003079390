import { useCallback, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useQuery } from 'react-query';

import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import axios, { AxiosResponse } from 'axios';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { Carregando } from '../../components';

interface Cadastro {
  id: number;
  email: string;
  cpf: string;
  nomeCompleto: string;
  dataNascimento: string;
  telefonePrincipal: string;
  telefoneSecundario?: string | null;
}

interface CadastroEndereco {
  id: number;
  cep: string;
  cidade: string;
  estado: string;
  bairro: string;
  endereco: string;
  numero: string;
  referencia?: string;
  complemento?: string;
  nomeDestinatario: string;
  utilizadoUltimoPedido?: boolean;
}

const AlterarCadastro = () => {
  const [user, setUser] = useState<Cadastro>({} as Cadastro);
  const [endereco, setEndereco] = useState<CadastroEndereco[]>([]);
  const [editando, setEditando] = useState(true);
  const [editandoEndereco, setEditandoEndereco] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const [carregando, setCarregando] = useState(true);

  const handleEdit = useCallback(() => {
    if (editando) {
      setEditando(false);
    } else {
      setEditando(true);
    }
  }, [editando]);

  useQuery('list-user', async () => {
    const responseCliente: AxiosResponse = await axios.get('/v1/minha-conta');
    setUser(responseCliente.data);
    setCarregando(false);

    const responseEndereco: AxiosResponse = await axios.get(
      '/v1/minha-conta/enderecos'
    );
    setEndereco(responseEndereco.data);
  });

  const handleEditEndereco = useCallback(() => {
    if (editandoEndereco) {
      setEditandoEndereco(false);
    } else {
      setEditandoEndereco(true);
    }
  }, [editandoEndereco]);

  const handleSubmit = useCallback(
    async (values: Cadastro) => {
      const ac = new AbortController();
      try {
        await axios.put('/v1/minha-conta', {
          ...values,

          cpf: user.cpf.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/g,
            '$1.$2.$3-$4'
          ),
        });
        enqueueSnackbar('Cadastro alterado com sucesso!', {
          variant: 'success',
        });
        setEditando(true);
      } catch (error) {
        enqueueSnackbar('Erro ao alterar Cadastro!', {
          variant: 'error',
        });
      }
      ac.abort();
    },
    [enqueueSnackbar, user]
  );

  const handleSubmitEndereco = useCallback(
    async (values: CadastroEndereco, idEndereco: number) => {
      try {
        await axios.put(`/v1/minha-conta/enderecos/ ${idEndereco}`, {
          ...values,
          cep: values.cep
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1'),
        });
        enqueueSnackbar('Endereço alterado com sucesso!', {
          variant: 'success',
        });
        setEditandoEndereco(true);
      } catch (error) {
        enqueueSnackbar('Erro ao aleterar Endereço', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  if (carregando) {
    return (
      <div className="flex p-11 justify-center items-center w-full mx-auto">
        <Carregando />
      </div>
    );
  }
  return (
    <div className="flex flex-col p-4 pt-0 ">
      <Formik
        initialValues={{
          id: user.id,
          email: user.email,
          cpf: user.cpf,
          nomeCompleto: user.nomeCompleto,
          dataNascimento: user.dataNascimento,
          telefonePrincipal: user.telefonePrincipal,
          telefoneSecundario: user.telefoneSecundario
            ? user.telefoneSecundario
            : '',
        }}
        onSubmit={handleSubmit}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <Typography
              className="flex justify-between, items-center w-full pt-4 pb-4"
              variant="h5"
            >
              {/* <b>Dados Pessoais</b> */}
            </Typography>
            <div className="flex  flex-col gap-4">
              <div className=" grid gap-6  md:grid-cols-2 ">
                <div>
                  <span className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email
                  </span>
                  <input
                    className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                      editando ? 'bg-gray-50 ' : 'bg-blue-50'
                    }`}
                    disabled={editando}
                    id="email"
                    key={values.id}
                    onChange={handleChange}
                    placeholder="E-mail"
                    required
                    type="text"
                    value={values.email}
                  />
                </div>
                <div>
                  <span className="block mb-2 text-sm font-medium text-gray-900 ">
                    Nome
                  </span>
                  <input
                    className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                      editando ? 'bg-gray-50 ' : 'bg-blue-50'
                    }`}
                    disabled={editando}
                    id="nomeCompleto"
                    onChange={handleChange}
                    placeholder="Nome"
                    required
                    type="text"
                    value={values.nomeCompleto}
                  />
                </div>
              </div>
              <div className=" grid gap-6  md:grid-cols-2 ">
                <div>
                  <span className="block mb-2 text-sm font-medium text-gray-900 ">
                    CPF
                  </span>
                  <ReactInputMask
                    className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-gray-50" `}
                    disabled
                    id="cpf"
                    mask="999.999.999-99"
                    maskPlaceholder={null}
                    onChange={handleChange}
                    placeholder="CPF"
                    required
                    type="text"
                    value={values.cpf}
                  />
                </div>
                <div className="flex flex-col justify-start items-start">
                  <span className="block mb-[-7px] text-sm font-medium text-gray-900 ">
                    Data Nascimento
                  </span>
                  <MuiPickersUtilsProvider
                    locale={ptBrLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      className={`" h-10 text-gray-900 text-sm items-center rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                        editando ? 'bg-gray-50 ' : 'bg-blue-50'
                      }`}
                      disabled={editando}
                      format="dd/MM/yyyy"
                      fullWidth
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      margin="normal"
                      onChange={(date) => setFieldValue('dataNascimento', date)}
                      required
                      style={{
                        display: 'flex',
                        padding: '6px',
                        borderColor: 'black',
                      }}
                      value={values.dataNascimento}
                      variant="inline"
                      // disableToolbar
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 ">
                <div>
                  <span className="block mb-2 text-sm font-medium text-gray-900 ">
                    Telefone Principal
                  </span>
                  <ReactInputMask
                    className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                      editando ? 'bg-gray-50 ' : 'bg-blue-50'
                    }`}
                    disabled={editando}
                    id="telefonePrincipal"
                    mask="(99) 99999-9999"
                    onChange={handleChange}
                    placeholder="Telefone Principal"
                    required
                    type="text"
                    value={values.telefonePrincipal}
                  />
                </div>

                <div>
                  <span className="block mb-2 text-sm font-medium text-gray-900 ">
                    Telefone Secundario
                  </span>
                  <ReactInputMask
                    className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                      editando ? 'bg-gray-50 ' : 'bg-blue-50'
                    }`}

                    disabled={editando}
                    id="telefoneSecundario"
                    mask="(99) 99999-9999"
                    onChange={handleChange}
                    placeholder="Telefone Principal"
                    required
                    type="text"
                    value={values.telefoneSecundario}
                  />
                </div>
              </div>
              <div className="flex space-x-2 ">
                <button
                  className={` "  inline-block px-6 py-2.5  font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out " ${
                    editando
                      ? 'bg-gray-200 text-gray-300 '
                      : 'bg-red-600 text-white hover:bg-red-700'
                  }`}
                  disabled={editando}
                  type="submit"
                >
                  Salvar
                </button>
                <button
                  className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                  onClick={handleEdit}
                  type="button"
                >
                  {editando ? 'Alterar Cadastro' : 'Cancelar'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex  flex-col gap-4">
        <Typography style={{ padding: '1rem 0 0 0' }} variant="h5">
          <b>Endereço de Cadastro</b>
        </Typography>
        {endereco.map(
          (enderecos) =>
            enderecos.utilizadoUltimoPedido === true && (
              <div key={enderecos.id}>
                <Formik
                  initialValues={{
                    id: enderecos.id,
                    cep: enderecos.cep,
                    endereco: enderecos.endereco,
                    bairro: enderecos.bairro,
                    numero: enderecos.numero,
                    cidade: enderecos.cidade,
                    estado: enderecos.estado,
                    referencia: enderecos.referencia
                      ? enderecos.referencia
                      : '',
                    complemento: enderecos.complemento
                      ? enderecos.complemento
                      : '',
                    nomeDestinatario: enderecos.nomeDestinatario
                      ? enderecos.nomeDestinatario
                      : '',
                    utilizadoUltimoPedido: enderecos.utilizadoUltimoPedido,
                  }}
                  onSubmit={(valuesEnd) =>
                    handleSubmitEndereco(valuesEnd, enderecos.id)
                  }
                >
                  {({ handleChange, values }) => (
                    <Form>
                      <div>
                        <div className="grid gap-6 md:grid-cols-4">
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Cep
                            </span>
                            <ReactInputMask
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="cep"
                              mask="99999-999"
                              maskPlaceholder={null}
                              onChange={handleChange}
                              placeholder="Cep"
                              required
                              type="text"
                              value={values.cep}
                            />
                            <Typography
                              style={{ display: 'contents' }}
                              variant="caption"
                            >
                              <Typography variant="inherit">
                                <a
                                  className="focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out hover:text-red-600 no-underline font-bold p-1
                              "
                                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Não sabe o CEP ?
                                </a>
                              </Typography>
                            </Typography>
                          </div>
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Rua
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="endereco"
                              onChange={handleChange}
                              placeholder="Rua"
                              required
                              type="text"
                              value={values.endereco}
                            />
                          </div>
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Bairro
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="bairro"
                              onChange={handleChange}
                              placeholder="Bairro"
                              required
                              type="text"
                              value={values.bairro}
                            />
                          </div>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-3">
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Número
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="numero"
                              onChange={handleChange}
                              placeholder="Número"
                              required
                              type="text"
                              value={values.numero}
                            />
                          </div>
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Complemento
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="complemento"
                              onChange={handleChange}
                              placeholder="Complemento"
                              required
                              type="text"
                              value={values.complemento}
                            />
                          </div>

                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Nome destinatário
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="nomeDestinatario"
                              onChange={handleChange}
                              placeholder="Nome Destinatario"
                              required
                              type="text"
                              value={values.nomeDestinatario}
                            />
                          </div>
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Referência
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="referencia"
                              onChange={handleChange}
                              placeholder="Referência"
                              required
                              type="text"
                              value={values.referencia}
                            />
                          </div>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Cidade
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="cidade"
                              onChange={handleChange}
                              placeholder="Cidade"
                              required
                              type="text"
                              value={values.cidade}
                            />
                          </div>
                          <div>
                            <span className="block mb-2 text-sm font-medium text-gray-900 ">
                              Estado
                            </span>
                            <input
                              className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                                editandoEndereco ? 'bg-gray-50 ' : 'bg-blue-50'
                              }`}
                              disabled={editandoEndereco}
                              id="estado"
                              onChange={handleChange}
                              placeholder="Estado"
                              required
                              type="text"
                              value={values.estado}
                            />
                          </div>
                        </div>
                        <div className="flex mb-6 space-x-2">
                          <button
                            className={` "  inline-block px-6 py-2.5  font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out " ${
                              editandoEndereco
                                ? ' bg-gray-200 text-gray-300 '
                                : 'bg-red-600 text-white hover:bg-red-700'
                            }`}
                            disabled={editandoEndereco}
                            type="submit"
                          >
                            Salvar
                          </button>
                          <button
                            className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                            onClick={handleEditEndereco}
                            type="button"
                          >
                            {editandoEndereco ? 'Alterar Endereço' : 'Cancelar'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )
        )}
      </div>
    </div>
  );
};
export default AlterarCadastro;
