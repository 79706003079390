/* eslint-disable import/prefer-default-export */
import axios from 'axios';

interface DadosUsuario {
  id?: string;
  email: string;
  nomeCompleto: string;
  cpf: string;
  OpcaoLGPD: string[];
  telefone: string;
  retificacaoOpcao: string;
  formaDeContato: string;
}
export interface Mensagem {
  id: string;
  text: string;
  from: string;
  createdAt: string;
  updatedAt: string;
  requestId: string;
}

export async function minhaConta(): Promise<any> {
  const response = await axios.get('/v1/minha-conta');
  return response;
}

export async function getSolicitacoes(cpf: string): Promise<any> {
  const response = await axios.get('/v2/lgpd/solicitacoes/todas/T?', {
    params: {
      clientId: cpf,
    },
  });
  return response;
}

export async function getSolicitacaoById(id: string): Promise<any> {
  const response = await axios.get(`/v2/lgpd/solicitacoes/${id}`);
  return response;
}

export async function findUrlAnexos(url: string): Promise<any> {
  const response = await axios.get('/v2/lgpd/urlArquivo?', {
    params: {
      nomeArquivo: url,
    },
  });
  return response;
}

export async function cancelarSolicitacao(id: string): Promise<any> {
  const response = await axios.post(
    `/v2/lgpd/solicitacoes/cancelar?solicitacaoId=${id}`
  );
  return response;
}

export async function novaSolicitacao(
  values: DadosUsuario,
  checado: string,
  cpf: string,
  checadoInfo: string,
  textMensgem?: string
): Promise<DadosUsuario> {
  const response = await axios.post(`/v2/lgpd/solicitacoes/nova`, {
    ...values,
    OpcaoLGPD: [checado],
    cpf: cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4'),
    formaDeContato: checadoInfo,
    mensagem: textMensgem,
  });
  return response.data;
}

export async function getMensagens(requestId: string): Promise<Mensagem[]> {
  const response = await axios.get(
    `/v2/lgpd/solicitacoes/${requestId}/mensagens`,
    {}
  );

  return response.data;
}

export async function enviarMensagem(data: {
  requestId: string;
  text: string;
}): Promise<Mensagem> {
  const response = await axios.post(`/v2/lgpd/mensagens`, {
    requestId: data.requestId,
    text: data.text,
  });

  return response.data;
}

export async function editarMensagem(data: {
  mensagemId: string;
  text: string;
}): Promise<Mensagem> {
  const response = await axios.put(`/v2/lgpd/mensagens/${data.mensagemId}`, {
    text: data.text,
  });

  return response.data;
}

export async function excluirMensagem(mensagemId: string): Promise<Mensagem> {
  const response = await axios.delete(`/v2/lgpd/mensagens/${mensagemId}`);

  return response.data;
}
