import { Typography } from '@material-ui/core';

import appStore from '../../assets/icones/App_Store_(iOS)-Logo.wine.svg';
import playStore from '../../assets/icones/Google_Play-Icon-Logo.wine.svg';
import iconeTina from '../../assets/images/Tina512V2.png';
import { Container } from '../../components';
import TitleV2 from '../../components/titleV2';

const links = [
  {
    nome: 'Portal do cliente',
    link: 'https://portal.martinello.com.br/',
    mensagem: 'Acesse nosso portal, consulte e pague suas parcelas',
    icone: (
      <img
        alt="icone"
        className="lg:h-[40px] lg:w-[40px] w-[80px]"
        src={iconeTina}
      />
    ),
  },

  {
    nome: 'APP para Android',
    link: 'https://play.google.com/store/apps/details?id=com.martinello.martinello',
    mensagem: 'Baixe nosso App Android',
    icone: (
      <img
        alt="icone"
        className="lg:h-[60px] lg:w-[40px] w-[50px]"
        src={playStore}
      />
    ),
  },
  {
    nome: 'APP para IOS',
    link: 'https://apps.apple.com/br/app/martinello/id1507818677',
    mensagem: 'Baixe nosso App IOS',
    icone: (
      <img
        alt="icone"
        className="lg:h-[60px] lg:w-[40px] w-[50px]"
        src={appStore}
      />
    ),
  },
];

const linksMarketing = [
  {
    nome: 'Martinello E-commerce',
    link: 'https://www.martinello.com.br/',
    mensagem: 'Acesse nosso site e conheça nossos Produtos',
    icone: (
      <img
        alt="icone"
        className="lg:h-[40px] lg:w-[40px] w-[80px]"
        src={iconeTina}
      />
    ),
  },
];

const CanaisDigitais = (): any => (
  <Container className="mt-6" style={{ marginTop: '4rem' }}>
    <div className="font-bold text-3xl text-[#c7303a]">
      <TitleV2
        color="#c7303a"
        enableGoBack
        fontSize={25}
        text="Canais Digitais"
      />
    </div>

    <div className="border-b-2 border-solid w-full " />

    <div className="flex flex-col pt-10 gap-4 p-4 max-w-full">
      {links.map((e) => (
        <a
          className="flex lg:h-[70px]  p-2 bg-slate-50 rounded-lg hover:bg-red-50 items-center gap-4"
          href={e.link}
          rel="noreferrer"
          target="_blank"
        >
          <div>{e.icone}</div>
          <div>
            <div className="flex items-center gap-2">
              <span className="text-lg font-bold">{e.nome}</span>
            </div>
            <div>
              <span className="flex text-sm text-[#c7303a]">{e.mensagem}</span>
            </div>
          </div>
        </a>
      ))}

      <div className="border-b-2 border-solid w-full py-1" />
      <div className="pt-5 pb-4">
        <h2 className="text-[20px] font-bold text-[#c7303a]">Acesse também</h2>
      </div>
      {linksMarketing.map((e) => (
        <a
          className="flex lg:h-[70px] gap-4 p-2 bg-slate-50 rounded-lg hover:bg-red-50 items-center"
          href={e.link}
          rel="noreferrer"
          target="_blank"
        >
          <div>{e.icone}</div>
          <div>
            <div className="flex items-center gap-2">
              <span className="text-lg font-bold">{e.nome}</span>
            </div>
            <div>
              <span className="flex text-sm text-[#c7303a]">{e.mensagem}</span>
            </div>
          </div>
        </a>
      ))}
    </div>
  </Container>
);

export default CanaisDigitais;
