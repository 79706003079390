import { StrictMode } from 'react';
import { render } from 'react-dom';

import DateFnsUtils from '@date-io/date-fns';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import { SnackbarProvider } from 'notistack';

import App from './App';
import theme from './constants/theme';

// const { CancelToken } = axios;
// let cancel: Canceler;

// const controller = new AbortController()

axios.interceptors.request.use(
  (config) => {
    const request = config;

    // console.log(request);

    // if (cancel) {
    //   cancel();
    // }

    // request.cancelToken = new CancelToken((c) => {
    //   cancel = c;
    // });

    // request.baseURL = 'http://localhost:5000';
    // request.baseURL = 'https://2t67kowff6.execute-api.sa-east-1.amazonaws.com/dev';
    request.baseURL = 'https://api-ecommerce.martinello.com.br';
    // request.baseURL = 'https://srv-docker-homologacao:5000';

    if (localStorage.getItem('token') && localStorage.getItem('usuario')) {
      const auth = localStorage.getItem('token');
      if (request && request.headers) {
        request.headers.Authorization = `Bearer ${auth}`;
      }
      // request.withCredentials = true
    }

    return request;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) =>
    // if (response.data) {
    //   // return success
    //   if (response.status === 200 || response.status === 201) {
    //     return response;
    //   }
    //   // reject errors & warnings
    //   return Promise.reject(response);
    // }

    // // default fallback
    // return Promise.reject(response);
    response,
  (error) => {
    // console.log(error.response?.config)
    if (
      error.response?.config.url !==
      'https://hm-api-ecommerce.martinello.com.br' &&
      (error.response?.status === 401 || error.response?.status === 403)
    ) {
      localStorage.clear();
      window.location.href = '/login';
    }

    // console.log(error);

    return Promise.reject(error);
  }
);

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

render(
  <StrictMode>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        maxSnack={3}
      >
        <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
