import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Modal } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import axios, { AxiosResponse } from 'axios';

import {
  cancelarSolicitacao,
  findUrlAnexos,
  getSolicitacaoById,
} from '../../api/lgpd/Lgpd';
import { Carregando } from '../../components';
import InsertHtmlDiv from '../../components/insertHtmlDiv';
import Mensagens from './mensagens/lgpd-mensagens';

interface DadosUsuario {
  email: string;
  nomeCompleto: string;
  cpf: string;
  OpcaoLGPD: string[];
  retificacaoOpcao: string;
  whatsApp: string;
}

interface DadosSolicitacao {
  id: string;
  clientId: string;
  reason: string;
  status: string;
  retificacaoOpcao: string;
  responseResolution: string;
  privacyPolicyId: string;
  createdAt: string;
  updatedAt: string;
  client: {
    name: string;
    id: string;
    documento: string;
    email: string;
    phone: string;
  };
  files: [
    {
      id: string;
      name: string;
      type: string;
      url: string;
      createdAt: string;
    }
  ];
  receiveInformationBy: string;
}

interface FilesSolicitacao {
  url: string;
}

const SolicitacaoDetalhe = (): any => {
  const [user, setUser] = useState<DadosUsuario>({} as DadosUsuario);
  const [solicitacao, setSolicitacao] = useState<DadosSolicitacao>(
    {} as DadosSolicitacao
  );
  const { view } = useParams<{ view: any }>();
  const [attachments, setAttachments] = useState<FilesSolicitacao[]>([]);
  const [carregando, setCarregando] = useState(true);
  const { id } = useParams<{ id: any }>();
  const [file, setFile] = useState<any>();
  const [openPDF, setOpenPDF] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const deleteAsync = async () => {
    setShowModal(true);
  };

  useEffect(() => {
    const findUser = async () => {
      try {
        const response: AxiosResponse = await getSolicitacaoById(id);
        setSolicitacao(response.data);

        setCarregando(false);
      } catch (error) {
        console.log(error);
      }
    };
    findUser();
  }, [id]);

  const findUrlAnexo = useCallback(async (url) => {
    try {
      const response: AxiosResponse = await findUrlAnexos(url);
      setFile(response.data.url);
      setOpenPDF(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const cancelSolitacao = useCallback(async () => {
    try {
      await cancelarSolicitacao(id);
      setShowModal(false);
      document.location.href = `/dados-cliente/lgpd`;
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  if (carregando) {
    return (
      <div className="flex lg:pl-[300px] lg:pt-[100px] mx-auto w-full min-h-[300px] justify-center">
        <Carregando />
      </div>
    );
  }

  return (
    <div className="flex flex-col  pt-0  lg:w-[800px] min-h-[300px]">
      <div className="flex flex-col mt-5 mb-5 gap-4 text-base">
        <div className="flex flex-col">
          <span className="text-gray-900  block mb-1 text-sm font-medium">
            Email
          </span>
          <div>
            <div className="block w-full max-w-lg p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500">
              {solicitacao.client.email}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-900  block mb-1 text-sm font-medium">
            Nome Completo
          </span>
          <span className="block w-full p-2.5 max-w-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {solicitacao.client.name}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-900  block mb-1 text-sm font-medium">
            CPF
          </span>
          <span className="block max-w-[240px] p-2.5  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {solicitacao.client.documento.replace(
              /(\d{3})(\d{3})(\d{3})(\d{2})$/g,
              '$1.$2.$3-$4'
            )}
          </span>
        </div>
      </div>
      {view === 'true' && (
        <div className="flex justify-center items-center text-[#CA181A] text-[20px] font-bold">
          <h1>Solicitação enviada</h1>
        </div>
      )}
      <div className="pt-4">
        <span className="font-bold text-[15px] ">Detalhes da Solicitação</span>
        <div
          className="flex flex-col items-center w-full pt-2 "
          key={solicitacao.id}
        >
          <div className="flex gap-2 items-center w-full">
            <span className="font-bold">Solicitação: </span>
            <span>{solicitacao.reason}</span>
          </div>
          <div className="flex gap-2 pt-1 items-center w-full">
            <span className="font-bold">Status: </span>
            <span>{solicitacao.status}</span>
          </div>
          {solicitacao.responseResolution && (
            <div className="flex pt-1 gap-2 items-center w-full">
              <span className="font-bold">Resposta: </span>
              <InsertHtmlDiv response={solicitacao.responseResolution} />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full pt-8">
          {solicitacao.files && Array.isArray(solicitacao.files) && (
            <span className="font-bold text-[15px]  ">Anexos:</span>
          )}

          {solicitacao.files &&
            Array.isArray(solicitacao.files) &&
            solicitacao.files.map((files) => (
              <span
                className="text-[#CA181A] overflow-hidden overflow-ellipsis whitespace-nowrap"
                key={files.id}
                onClick={() => findUrlAnexo(files.name)}
                onKeyPress={() => findUrlAnexo(files.name)}
                role="button"
                tabIndex={0}
              >
                {files.name}
              </span>
            ))}
        </div>
        <Mensagens requestStatus={solicitacao.status}/>

        <Modal
          className="flex z-50 items-center justify-center"
          onClose={() => {
            setOpenPDF(false);
            setFile('');
          }}
          open={openPDF}
        >
          <Box className="bg-[#F4F4F4] w-[90vw] h-[90vh] p-4 rounded-lg duration-1000">
            <div className="flex w-full justify-end">
              <button
                aria-label="Fechar"
                className=" hover:text-red-600 transition-colors duration-75"
                onClick={() => {
                  setOpenPDF(false);
                  setFile('');
                }}
                type="button"
              >
                <CloseOutlined />
              </button>
            </div>
            {carregando && (
              <div className="flex h-full w-full justify-center">
                <Carregando />
              </div>
            )}
            <section
              className={`${
                carregando ? 'hidden' : 'flex'
              } flex-col h-full gap-6 justify-around`}
            >
              <div className="h-full flex-row flex-wrap w-full items-center justify-around flex gap-4">
                <iframe className="w-full h-[95%]" src={file} title="Anexo" />
              </div>
            </section>
          </Box>
        </Modal>
        {view === 'false' && solicitacao.status === 'AGUARDANDO RESPOSTA'
           && (
            <div className="flex justify-center items-center pt-10">
              <button
                className="flex p-2 bg-[#CA181A] hover:bg-red-500 rounded text-white font-bold min-w-[300px] justify-center"
                onClick={deleteAsync}
                type="button"
              >
                Cancelar Solicitação
              </button>
            </div>
          )}

        {showModal && (
          <div
            aria-labelledby="modal-title"
            aria-modal="true"
            className="fixed z-10 inset-0 overflow-y-auto"
            role="dialog"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                aria-hidden="true"
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
              <span
                aria-hidden="true"
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
              >
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Você realmente deseja cancelar?
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={cancelSolitacao}
                    type="button"
                  >
                    Confirmar
                  </button>

                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowModal(false)}
                    type="button"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolicitacaoDetalhe;
