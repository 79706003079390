/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction } from 'react';

import CloseIcon from '@mui/icons-material/Close';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  titulo?: string;
  footer?: React.ReactNode;
  className?: string;
  closeButton?: boolean;
  children?: React.ReactNode;
}
export default function CustomModal({
  children,
  isOpen,
  setIsOpen,
  titulo,
  footer,
  className,
  closeButton,
}: Props) {
  if (isOpen)
    return (
      <div
        aria-labelledby="modal-title"
        aria-modal="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        role="dialog"
      >
        <div className="h-screen pt-4 px-4 pb-20 text-center flex items-center justify-center">
          <div
            aria-hidden="true"
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <span
            aria-hidden="true"
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
          >
            &#8203;
          </span>
          <div className={` ${className} inline-block p-5 mx-auto my-auto align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-[90vw] max-h-[90vh]`}>
            <div className="mb-3 w-full flex items-center justify-between">
              <h2 className="font-bold text-xl">{titulo}</h2>
              <button
                className="hover:text-red-600 cursor-pointer"
                onClick={() => setIsOpen(false)}
                type="button"
              >
                {closeButton && <CloseIcon />}
              </button>
            </div>
            <div className="">
              {children}
            </div>

            <div className="">
              {footer}
            </div>
          </div>
        </div>
      </div>
    );

  return <div><p> </p></div>;
}
