import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import theme from '../constants/theme';

interface TitleProps {
  color?: string;
  enableGoBack?: boolean;
  text: string;
  style?: CSSProperties;
  fontSize?: number;
  icon?: any;
}

const TitleV2 = ({
  color = 'black',
  enableGoBack,
  style,
  text,
  fontSize,
  icon
}: TitleProps): JSX.Element => {
  const history = useNavigate();

  return (
    <div style={{ alignItems: 'center', display: 'flex', ...style }}>
      {
        enableGoBack && (
          <IconButton onClick={() => history(-1)} style={{ height: 'fit-content' }}>
            <ArrowBack color="primary" />
          </IconButton>
        )
      }
      <h3 style={{ paddingLeft: '1rem' }}>
        <strong style={{ color, fontSize: `${fontSize}px` }}>
          {text}
        </strong>
      </h3>
      <div style={{ paddingLeft: '.5rem', display: 'flex', alignItems: 'center', color }}>
        {icon}
      </div>
    </div>
  );
};

TitleV2.defaultProps = {
  color: undefined,
  enableGoBack: false,
  style: undefined,
  fontSize: 24,
  icon: undefined,
};

export default TitleV2;
