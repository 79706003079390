import {
  Card,
  Container,
  Grid,
  Typography
} from '@material-ui/core';

import img404 from '../../assets/images/404.png';

const NotFound = (): JSX.Element => (
  <div style={{padding: '1rem'}}>
    <Grid container justifyContent="center" style={{ padding: '1rem' }}>
      <Typography
        color="primary"
        style={{ fontSize: '1.5rem', fontWeight: 600 }}
      >
        Pagina não encontrada
      </Typography>
    </Grid>
    <Card style={{ padding: '1rem' }}>
      <Container style={{ textAlign: 'center' }}>
        <img
          alt="404"
          src={img404}
          style={{ height: 'auto', maxWidth: '100%' }}
        />
      </Container>
    </Card>
  </div>
);

export default NotFound;
