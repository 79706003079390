import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid, Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { Container } from '../../components';
import theme from '../../constants/theme';
import UserContext from '../../contexts/user';
import CadastroFormulario from './cadastro-formulario';
import CadastroFormularioPj from './cadastro-formulario-pj';

const TypographyLink = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

const Cadastro = () => {
  const navigate = useNavigate();
  const { handleLoginCpf, handleLoginCnpj } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [cadastroCnpj, setCadastroCnpj] = useState(false);

  const { user } = useParams();

  const toggleLogin = useCallback(() => {
    setCadastroCnpj((prevState) => !prevState);
  }, []);

  const handleCadastroCnpj = useCallback(
    async (values) => {
      try {
        await axios.post('/v1/auth/cadastro-pj', values);
        enqueueSnackbar('Novo usuário cadastrado com sucesso!', {
          variant: 'success',
        });
        await handleLoginCnpj(values.cnpj, values.senha);
        navigate('/');
      } catch (error) {
        if (error?.response) {
          if (error?.response.data.message === 'Usuário já possui cadastro.') {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            setTimeout(() => {

              navigate('/login');
            }, 700)
          } else {

            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Não foi possível realizar o cadastro.', {
            variant: 'error',
          });
        }
      }
    },
    [enqueueSnackbar, handleLoginCnpj, navigate]
  );

  const handleCadastroCpf = useCallback(
    async (values) => {
      try {
        let dataNascimento = null;
        const nomeDestinatario = values.nomeCompleto;
        if (values.dataNascimento) {
          const [dia, mes, ano] = values.dataNascimento.split('/');
          dataNascimento = `${ano}-${mes}-${dia}`;
        }
        await axios.post('/v1/auth/cadastro', {
          ...values,
          dataNascimento,
          nomeDestinatario,
        });
        enqueueSnackbar('Novo usuário cadastrado com sucesso!', {
          variant: 'success',
        });
        await handleLoginCpf(values.email, values.senha);
        navigate('/');
      } catch (error) {
        if (error?.response) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Não foi possível realizar o cadastro.', {
            variant: 'error',
          });
        }
      }
    },

    [enqueueSnackbar, handleLoginCpf, navigate]
  );

  useEffect(() => {
    if (user === 'true') {
      toggleLogin()
    }
  }, [user, toggleLogin])

  return (
    <Container className="mt-6" style={{marginTop: '2rem'}}>
      <Paper elevation={4} style={{ padding: '1rem' }}>
        <Grid item style={{ paddingLeft: 5 }}>
          <TypographyLink
            onClick={toggleLogin}
            style={{ display: 'contents' }}
            variant="body2"
          >
            {cadastroCnpj
              ? 'Você é pessoa física? Cadastre-se com seu e-mail'
              : 'Você é pessoa jurídica? Cadastre-se com seu CNPJ clicando aqui!'}
          </TypographyLink>
        </Grid>
        {cadastroCnpj ? (
          <CadastroFormularioPj onSubmit={handleCadastroCnpj} />
        ) : (
          <CadastroFormulario onSubmit={handleCadastroCpf} />
        )}
      </Paper>
    </Container>
  );
};

export default Cadastro;
