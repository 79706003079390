import { ReactComponent as Aviso } from '../assets/svgs/aviso.svg';

interface FloatingButtonProps {
  onClick: () => void; // Função a ser chamada quando o botão for clicado
}

interface CustomWindow extends Window {
  sendAdoptCommand: (command: string) => void;
}

declare let window: CustomWindow;

const FloatingButton = () => {
  const handleAdoptCommand = () => {
    window.sendAdoptCommand('open');
  };
  return (
    <Aviso
      className="fixed bottom-2 left-2 p-3 cursor-pointer z-[9999]   rounded-full"
      fill="#d69f9f"
      onClick={handleAdoptCommand}
      opacity={0.5}
      style={{ width: '85px', height: '85px' }}
    />
  );
};
export default FloatingButton;
