import { FormEvent, useCallback, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Flex, PasswordCustomField, TextFieldMask } from '../../components';

interface LoginModalRecuperacaoSenhaProps {
  toggleModal: () => void;
  modal: boolean;
  loginCnpj: boolean;
}

const LoginModalRecuperacaoSenha = ({
  toggleModal,
  modal,
  loginCnpj,
}: LoginModalRecuperacaoSenhaProps): JSX.Element => {
  const [emailRecuperacao, setEmailRecuperacao] = useState('');
  const [senhaRecuperacao, setSenhaRecuperacao] = useState('');
  const [cnpjRecuperacao, setCnpjRecuperacao] = useState('');
  const [codigoVendaRecuperacao, setCodigoVendaRecuperacao] = useState('');
  const [senhaConfimRecuperacao, setSenhaConfirmRecuperacao] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleRecuperacao = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      if (e) e.preventDefault();
      if (loginCnpj) {
        axios
          .put('/v1/auth/recuperar-senha-pj', {
            cnpj: cnpjRecuperacao,
            codigoVenda: codigoVendaRecuperacao,
            senha: senhaRecuperacao,
            senhaConfirmacao: senhaConfimRecuperacao,
          })
          .then((response) => {
            enqueueSnackbar(response.data.message, { variant: 'success' });
            setSenhaRecuperacao('');
            setCnpjRecuperacao('');
            setCodigoVendaRecuperacao('');
            setSenhaConfirmRecuperacao('');
            toggleModal();
          })
          .catch((error) => {
            if (error?.response) {
              enqueueSnackbar(error.response.data.message, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Não foi possível recuperar a senha', {
                variant: 'error',
              });
            }
          });
      } else {
        axios
          .post('/v1/auth/recuperar-senha', { email: emailRecuperacao })
          .then((response) => {
            enqueueSnackbar(response.data.message, { variant: 'success' });
            setEmailRecuperacao('');
            toggleModal();
          })
          .catch((error) => {
            if (error?.response) {
              enqueueSnackbar(error.response.data.message, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Não foi possível recuperar a senha', {
                variant: 'error',
              });
            }
          });
      }
    },
    [
      cnpjRecuperacao,
      codigoVendaRecuperacao,
      emailRecuperacao,
      enqueueSnackbar,
      loginCnpj,
      senhaConfimRecuperacao,
      senhaRecuperacao,
      toggleModal,
    ]
  );

  useEffect(() => {
    setSenhaRecuperacao('');
    setCnpjRecuperacao('');
    setCodigoVendaRecuperacao('');
    setSenhaConfirmRecuperacao('');
    setEmailRecuperacao('');
  }, [modal]);

  return (
    <Dialog fullWidth onClose={toggleModal} open={modal}>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Typography color="primary" gutterBottom variant="h6">
            Recuperação de senha
          </Typography>
          <IconButton aria-label="close" onClick={toggleModal} size="small">
            <CloseOutlined color="action" />
          </IconButton>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleRecuperacao}>
          <Grid container spacing={2}>
            {loginCnpj ? (
              <>
                <Grid item xs={12}>
                  <TextFieldMask
                    fullWidth
                    handleChange={(e) => setCnpjRecuperacao(e.target.value)}
                    label="CNPJ"
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    placeholder="CNPJ"
                    required
                    type="text"
                    value={cnpjRecuperacao}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldMask
                    fullWidth
                    handleChange={(e) =>
                      setCodigoVendaRecuperacao(e.target.value)
                    }
                    label="Código de Venda"
                    mask="9999999999999"
                    name="codigoVenda"
                    placeholder="Codigo de Venda"
                    required
                    type="text"
                    value={codigoVendaRecuperacao}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordCustomField
                    handleChange={(e) => setSenhaRecuperacao(e.target.value)}
                    id="senha-recuperacao"
                    name="senha"
                    value={senhaRecuperacao}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordCustomField
                    handleChange={(e) =>
                      setSenhaConfirmRecuperacao(e.target.value)
                    }
                    id="senha-confirm-recuperacao"
                    label="Confirme a senha *"
                    name="senha"
                    value={senhaConfimRecuperacao}
                  />
                </Grid>
              </>
            ) : (
              <TextField
                fullWidth
                label="E-mail"
                margin="normal"
                name="email"
                onChange={(e) => setEmailRecuperacao(e.target.value)}
                placeholder="E-mail para recuperação de senha"
                required
                type="email"
                value={emailRecuperacao}
                variant="outlined"
              />
            )}
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '1rem 0',
            }}
          >
            <Button color="primary" type="submit" variant="contained">
              Enviar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModalRecuperacaoSenha;
