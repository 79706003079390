import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";

import UserContext from "../contexts/user";

const PrivateRoute = ({children,...rest }:any): JSX.Element => {
  const {isAuthenticated} = useContext(UserContext);
  if(!isAuthenticated){
    return <Navigate state={{ from: rest.location }} to={{ pathname: `/login${window.location.pathname}` }} />
  }

  return children;
};

export default PrivateRoute;
