import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getMensagens, Mensagem } from '../../../api/lgpd/Lgpd';
import ModalMensagem from './modal-mensagem';
import TemplateMensagem from './template-mensagem';

interface Props {
  requestStatus: string;
}
export default function Mensagens({ requestStatus }: Props) {
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const { id } = useParams();
  const [mensagens, setMensagens] = useState<Array<Mensagem>>([]);
  const divScrollRef: any = useRef(null);
  const [editMensagem, setEditMensagem] = useState<Mensagem | null>(null);
  const [load, setLoad] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function buscarMensagens() {
    const response = await getMensagens(id as string);
    setMensagens(response);
    if (divScrollRef.current) {
      divScrollRef.current.scrollTop = divScrollRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    (async () => {
      await buscarMensagens();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalMessage]);

  function handleEditarMensagem(mensagem: Mensagem) {
    setEditMensagem(mensagem);
    setOpenModalMessage(true);
  }

  useEffect(() => {
    if (openModalMessage === false) {
      setEditMensagem(null);
    }
  }, [openModalMessage]);

  return (
    <>
      <section className="flex p-4 flex-col gap-4 border-[1px] mt-5 w-full max-w-[90vw]">
        <span className="flex text-[20px] font-bold text-[#CA181A] flex-wrap">
          Mensagens
        </span>
        <div
          className="max-h-96 overflow-x-auto  w-full p-2"
          ref={divScrollRef}
        >
          {mensagens.length <= 0 && (
            <div className="mx-auto w-44">Nenhuma mensagem</div>
          )}
          {mensagens.map((mensagem) => (
            <div key={mensagem.id}>
              <TemplateMensagem
                // eslint-disable-next-line react/jsx-no-bind
                getMensagens={buscarMensagens}
                mensagem={mensagem}
                // eslint-disable-next-line react/jsx-no-bind
                setEditMensagem={handleEditarMensagem}
                status={requestStatus}
              />
            </div>
          ))}
        </div>
        <div className="w-full flex items-end justify-end">
          {requestStatus === 'AGUARDANDO RESPOSTA' && (
            <button
              className={`flex rounded bg-[#B80008] p-1 text-white
					hover:bg-red-800 text-center justify-center items-center font-bold lg:w-[250px] `}
              onClick={() => setOpenModalMessage(true)}
              type="button"
            >
              Nova Mensagem
              {/* {isLoading ? <FaSpinner className="animate-spin" /> : ""} */}
            </button>
          )}
        </div>
      </section>
      <ModalMensagem
        editMensagem={editMensagem}
        openModal={openModalMessage}
        setOpenModal={setOpenModalMessage}
        // getMensagens={getMensagens()}
      />
    </>
  );
}
