import { FC } from 'react';

import { UserContextProvider } from './user';

const GlobalContext: FC = ({ children }) => (
  <UserContextProvider>
    {children}
  </UserContextProvider>
);

export default GlobalContext;
