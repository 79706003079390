import { useCallback, useState } from 'react';

import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { FileCopyOutlined, GetAppOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

import { copy, toReal } from '../helpers/utils';
import Flex from './flex';

export default function Boleto(boleto: any): JSX.Element {
  const { linhaDigitavel, valorTotal, dataVencimento, codigoBarras, link } =
    boleto.boleto;
  const [copiado, setCopiado] = useState(false);

  const handleCopy = useCallback(() => {
    copy(linhaDigitavel);
    setCopiado(true);
  }, [linhaDigitavel]);

  return (
    <Card style={{ overflow: 'auto' }}>
      <CardContent>
        <Flex alignItems="center" direction="column">
          <Typography variant="h5">
            <b>{toReal(valorTotal)}</b>
          </Typography>
          <Typography
            style={{ textTransform: 'uppercase' }}
            variant="subtitle1"
          >
            Vencimento{' '}
            {format(new Date(dataVencimento), 'dd/MMMM', {
              locale: ptBrLocale,
            })}
          </Typography>
          <Typography style={{ margin: '1rem 0' }} variant="body2">
            Utilize o número do código de barras abaixo para fazer o pagamento
          </Typography>
          <Typography style={{ fontSize: 16 }} variant="subtitle2">
            {codigoBarras}
          </Typography>
          <Grid container spacing={1} style={{ marginTop: '1rem' }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={handleCopy}
                size="large"
                startIcon={<FileCopyOutlined />}
                style={
                  copiado
                    ? { backgroundColor: `#4cb050`, color: '#fff' }
                    : { color: `#444` }
                }
                variant="outlined"
              >
                {copiado ? 'Copiado com sucesso!' : 'Copiar Código'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={() => window.open(link, '_blank')}
                size="large"
                startIcon={<GetAppOutlined />}
                style={{ color: '#333' }}
                variant="outlined"
              >
                Baixar Boleto
              </Button>
            </Grid>
          </Grid>
        </Flex>
      </CardContent>
    </Card>
  );
}
