import { useEffect, useState } from 'react';

// import { Typography } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';

import { Carregando } from '../../components';

export interface PoliticaPrivacidade {
  id: string;
  version: string;
  text: string;
  cretedAt: string;
}

const Privacidade = () => {
  const [privacidade, setPrivacidade] = useState<PoliticaPrivacidade>();
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: AxiosResponse = await axios.get(
          '/v2/lgpd/politicaPrivacidade'
        );
        setPrivacidade(response.data);
        setCarregando(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  function exibirHTML(htmlString: string): void {
    const container = document.getElementById('container'); // ID do elemento onde você deseja exibir o HTML
    // Define o conteúdo HTML dentro do elemento
    if (container) {
      container.innerHTML = htmlString;
    }
  }

  exibirHTML(privacidade?.text || '');

  return (
    <div className="flex flex-col gap-4">
      {carregando && (
        <div className="flex p-11 lg:pl-[300px] justify-center items-center w-full mx-auto">
          <Carregando />
        </div>
      )}
      <div className="flex flex-col">
        <span id="container" />
        {!carregando && (
          <a
            className="focus:outline-none focus:ring-0  text-blue-700 active:underline transition duration-150 ease-in-out no-underline text-[15px] pt-4"
            href="/dados-cliente/lgpd"
          >
            Solicitar detalhes da LGPD
          </a>
        )}
      </div>
    </div>
  );
};
export default Privacidade;
