/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export interface Servicos {
  idVenda: number
  idRegistro: string
  nota: Nota
  data: string
  valorAPrazo: string
  valorAVista: string
  valorTotalProdutos: number
  valorTotalServicos: number
  itens: Item[]
  vendaServicos: any[]
  loja: Loja
}

export interface Nota {
  idRegistro: string
  idNotaFiscal: number
  chaveSGL: string
  numeroNF: number
  valorTotalNF: number
}

export interface Item {
  quantidade: string
  produto: Produto
  valorAVista: number
  servicos: any[]
}

export interface Produto {
  codigo: string
  descricao: string
}

export interface Loja {
  codigo: string
}

export async function getServicos(idVenda: number): Promise<any> {
  const response = await axios.get(`/v2/parcelas/servicos/${idVenda}`,{
    withCredentials: true,
  });

  return response.data;
}
