import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  AccountCircleOutlined,
  ExpandLessOutlined,
  MonetizationOnOutlined,
  PeopleOutlined,
} from '@material-ui/icons';
import { EnterIcon, ExitIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import logoV2 from '../assets/images/LogoMart.png';
import { Container, Flex } from '../components';
import theme from '../constants/theme';
import UserContext from '../contexts/user';
import { ModalAtendimento } from './ModalContato';

const Navbar = styled.header`
  background: #ca181a;
  box-shadow: 0px 4px 2px -2px #b80008;
  left: 0;
  min-height: 80px;
  top: 0;
  position: fixed;
  transition: all 300ms ease-in-out;
  width: 100%;
  z-index: 101;
`;

const Menu = styled.ul<{ isOpen: boolean }>`
  align-items: center;
  display: flex;
  list-style: none;
  flex-flow: row nowrap;
  color: #ffff;

  @media (max-width: 1000px) {
    display: flex;
    flex-flow: column nowrap;
    /* align-content: center; */
    /* justify-content: flex-start; */
    background-color: #ffff;
    position: fixed;
    transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(100%)'};
    top: 0px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    padding: 40px;
    transition: transform 0.3s ease-in-out;
    padding-inline-start: -40px;
    align-items: flex-start;
    color: ${({ isOpen }) => (isOpen ? '#ff0000 ' : '#ffff')};
  }
`;

const MenuItem = styled(Link)`
  /* color: #fff; */
  display: flex;
  font-size: 1.3em;
  margin-right: 1.5rem;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: baseline;
  padding: 4px;

  &:hover {
    /* font-size: 19px; */
    transform: scale(1.1);
  }

  @media (max-width: 1000px) {
    font-size: 1.3rem;
    padding: 0.6rem;
    margin-right: 0;
  }
`;

const Hamburger = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  /* top: 15px; */
  right: 20px;
  z-index: 20;
  display: none;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ccc' : '#ffffff')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
`;

const MenuItemLogin = styled(MenuItem)`
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 5px;
  cursor: pointer;
  font-size: inherit;
  font-weight: bold;
  margin-right: 0;
  margin-left: 0;
  padding: 8px 30px;
  align-items: center;
  gap: 5px;
  /* background: #fff; */

  &:hover {
    transform: scale(1.1);
    color: #fff;
  }

  @media (max-width: 500px) {
    /* font-size: 2rem; */
    margin: 0.6rem;
  }
`;

const ButtonToTop = styled.button`
  border: none;
  background-color: ${theme.palette.primary.main};
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: -85vh;
  right: 2rem;
  color: white;
  -webkit-box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.37);
  cursor: pointer;
  &:hover {
    -webkit-box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.41);
    box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.41);
    transform: scale(103%);
  }
`;

const Header = (): JSX.Element => {
  const { isAuthenticated, usuario } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const toggleModalAtendimento = useCallback(() => {
    setIsModalAtendimentoOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    <Navbar style={{ flex: 2 }}>
      <Container id="#top-page">
        <Flex alignItems="center" justifyContent="space-between">
          {window.location.pathname === "/" && <button onClick={() => { navigate(0) }} type='button'>
            <div className="p-2">
              <img alt="Logo - Martinello" className="h-[80px]" src={logoV2} />
            </div>
          </button>}
          {window.location.pathname !== "/" &&
            <Link to="/">
              <div className="p-2">
                <img alt="Logo - Martinello" className="h-[80px]" src={logoV2} />
              </div>
            </Link>
          }
          <nav className="flex items-center">
            <Hamburger onClick={toggleMenu} open={isOpen}>
              <div />
              <div />
              <div />
            </Hamburger>
            <Menu isOpen={isOpen}>
              {/*  <MenuItem onClick={toggleMenu} to="/">
                Home
              </MenuItem> */}
              <MenuItem onClick={toggleModalAtendimento} to="#">
                Atendimento
                <span style={{ marginLeft: '0.5rem' }}>
                  <PeopleOutlined />
                </span>
              </MenuItem>
              <MenuItem onClick={toggleMenu} to="/parcelas">
                Crediário
                <span style={{ marginLeft: '0.5rem' }}>
                  <MonetizationOnOutlined />
                </span>
              </MenuItem>
              {isAuthenticated && usuario?.pessoaJuridica === false && (
                <MenuItem onClick={toggleMenu} to="/dados-cliente/alterar-cadastro">
                  Olá, {usuario?.nome.split(' ')[0]}{' '}
                  <span style={{ marginLeft: '0.5rem' }}>
                    <AccountCircleOutlined />
                  </span>
                </MenuItem>
              )}
              <MenuItemLogin
                onClick={toggleMenu}
                style={{
                  border: isAuthenticated
                    ? `2px solid ${theme.palette.primary.main}`
                    : undefined,
                }}
                to={isAuthenticated ? '/logout' : '/login'}
              >
                {isAuthenticated ? <ExitIcon /> : <EnterIcon />}
                {isAuthenticated ? 'Sair' : 'Login'}
              </MenuItemLogin>
            </Menu>
          </nav>
        </Flex>
      </Container>
      {showButton ? (
        <ButtonToTop
          onClick={() =>
            window.scrollTo({ top: 0, left: 100, behavior: 'smooth' })
          }
          type="button"
        >
          <ExpandLessOutlined fontSize="large" />
        </ButtonToTop>
      ) : null}
      <ModalAtendimento isModalAtendimentoOpen={isModalAtendimentoOpen} toggleModalAtendimento={toggleModalAtendimento} />
    </Navbar>
  );
};

export default Header;
