import { FormEvent, useCallback, useContext, useState } from 'react';
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { MailOutlined, PersonOutline } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import appLogoTinaV2 from '../../assets/images/Tina512V3.png';
import {
  ButtonExtended,
  CnpjField,
  Container,
  Flex,
  PasswordCustomField,
} from '../../components';
import theme from '../../constants/theme';
import UserContext from '../../contexts/user';
import LoginModalRecuperacaoSenha from './login-modal-recuperacao-senha';

const TypographyLink = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${theme.palette.primary.main};
  }
  a:visited {
    color: black;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    color: ${theme.palette.primary.main};
  }

  a:visited {
    color: #000;
  }
`;

const Login = (): JSX.Element => {
  const [carregando, setCarregando] = useState(false);
  const [email, setEmail] = useState('');

  const [cnpj, setCnpj] = useState('');
  const [senha, setSenha] = useState('');
  const [loginCnpj, setLoginCnpj] = useState(false);
  const [modal, setModal] = useState(false);
  const { redir, redir2 } = useParams();

  const { isAuthenticated, handleLoginCpf, handleLoginCnpj } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const toggleLogin = useCallback(() => {
    if (!loginCnpj) {
      setCnpj('');
      setLoginCnpj(true);
    } else {
      setEmail('');
      setLoginCnpj(false);
    }
  }, [loginCnpj]);

  const toggleModal = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);

  const handleLogin = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      if (e) e.preventDefault();
      setCarregando(true);
      if (loginCnpj) {
        try {
          await handleLoginCnpj(cnpj, senha);
          enqueueSnackbar('Usuário autenticado com sucesso!', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          const to = redir || '/';
          if (to !== '/') {
            navigate(`/${to}${redir2 ? `/${redir2}` : ''}`);
          }
        } catch (error) {
          setCarregando(false);
          if (error?.response) {
            enqueueSnackbar(error.response.data.message, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Não foi possível realizar seu login.', {
              variant: 'error',
            });
          }
        }
      } else {
        try {
          await handleLoginCpf(email, senha);
          enqueueSnackbar('Usuário autenticado com sucesso!', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          const to = redir || '/';
          if (to !== '/') {
            navigate(`/${to}${redir2 ? `/${redir2}` : ''}`);
          }
        } catch (error) {
          setCarregando(false);
          if (error?.response) {
            enqueueSnackbar(error.response?.data?.message, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Não foi possível realizar seu login.', {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }
      }
    },
    [
      loginCnpj,
      cnpj,
      email,
      senha,
      redir,
      redir2,
      navigate,
      enqueueSnackbar,
      handleLoginCpf,
      handleLoginCnpj,
    ]
  );

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  // console.log(redir);

  return (
    <>
      <Container style={{ paddingTop: '4rem' }}>
        <Paper elevation={4} style={{ padding: '1rem' }}>
          <form onSubmit={handleLogin}>
            <Flex justifyContent="center">
              {/* <img alt="Logo" className="h-[200px]" src={appLogoTina} /> */}
              <img alt="Logo" className="h-[200px]" src={appLogoTinaV2} />
            </Flex>
            {/* <Typography style={{ textAlign: 'center' }} variant="h6">
              Login
            </Typography> */}
            <Flex justifyContent="center">
              <Grid container direction="column" style={{ maxWidth: 450 }}>
                <Grid item>
                  {!loginCnpj && (
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlined htmlColor="gray" />
                          </InputAdornment>
                        ),
                      }}
                      label="E-mail"
                      margin="normal"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="E-mail"
                      required
                      type="email"
                      value={email}
                      variant="outlined"
                    />
                  )}
                  {loginCnpj && (
                    <CnpjField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutline htmlColor="gray" />
                          </InputAdornment>
                        ),
                      }}
                      label="CNPJ"
                      margin="normal"
                      name="cnpj"
                      onChange={(e) => setCnpj(e.target.value)}
                      placeholder="CNPJ"
                      required
                      value={cnpj}
                      variant="outlined"
                    />
                  )}
                </Grid>
                <Grid item style={{ paddingLeft: 5 }}>
                  <TypographyLink
                    className="text-red-600"
                    onClick={toggleLogin}
                    style={{ display: 'contents' }}
                    variant="body1"
                  >
                    {loginCnpj
                      ? 'Você é pessoa física? Acesse com seu e-mail'
                      : 'Clique aqui para acessar com seu CNPJ'}
                  </TypographyLink>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <PasswordCustomField
                    handleChange={(e) => setSenha(e.target.value)}
                    id="senha"
                    label="Senha *"
                    name="senha"
                    value={senha}
                  />
                </Grid>
                <Grid className="pt-2" item style={{ paddingLeft: 5 }}>
                  {loginCnpj ? (
                    <Typography variant="body2">
                      <span>Esqueceu sua senha? </span>
                      <TypographyLink onClick={toggleModal} variant="inherit">
                        Clique aqui
                      </TypographyLink>
                      <span> para criar uma nova senha</span>
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <span>Esqueceu sua senha? </span>
                      <TypographyLink onClick={toggleModal} variant="inherit">
                        Clique aqui
                      </TypographyLink>
                      <span> para recebê-la por e-mail</span>
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '1rem 0',
                  }}
                >
                  <ButtonExtended
                    color="primary"
                    loading={carregando}
                    type="submit"
                    variant="contained"
                  >
                    Entrar
                  </ButtonExtended>
                </Grid>
                <p className="self-center font-500 text-xl">ou</p>
                <Grid
                  item
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button color="primary" variant="text">
                    {/* <CustomLink
                      className="text-base font-bold bg-slate-300 py-2 px-4 rounded"
                      style={{ color: '#000' }}
                      to={`/cadastro/isCnpj=${loginCnpj}`}
                    >
                      Cadastrar-se
                    </CustomLink> */}
                    <a
                      className="text-base font-bold bg-slate-300 py-2 px-4 rounded"
                      href={`/cadastro/${loginCnpj}`}
                      style={{ color: '#000' }}
                    >
                      Cadastrar-se
                    </a>
                  </Button>
                </Grid>
              </Grid>
            </Flex>
          </form>
        </Paper>
      </Container>
      <LoginModalRecuperacaoSenha
        loginCnpj={loginCnpj}
        modal={modal}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default Login;
