import { useCallback, useEffect, useState } from 'react';

import { CircularProgress, Drawer, Typography } from '@material-ui/core';
import { PictureAsPdfSharp } from '@material-ui/icons';
import axios from 'axios';
import styled from 'styled-components';

import type { ParcelaApi } from '.';
import { getServicos, Servicos } from '../../api/servicos';
import { ButtonExtended, Flex, ParcelaDetalhes } from '../../components';
import Accordion from '../../components/accordion';
import AccordionProduto from '../../components/accordionProduto';
import { toReal } from '../../helpers/utils';

const StyledDrawer = styled(Drawer)`
  /* background: rgba(244, 244, 244, 1); */
  .MuiDrawer-paper {
    width: 370 !important;
  }

  @media (max-width: 420px) {
    .MuiDrawer-paper {
      width: 86% !important;
    }
  }
`;

interface DrawerParcelaDetalhesProps {
  onClose: () => void;
  open: boolean;
  parcela: ParcelaApi;
}

interface NotaFiscal {
  numero: number;
  dataEmissao: string;
  valorTotal: number;
  chave: string;
  itens: {
    cod: string;
    descricao: string;
    quantidade: number;
    valorUnitario: number;
    valorTotal: number;
  }[];
}

const DrawerParcelaDetalhes = ({
  onClose,
  open,
  parcela,
}: DrawerParcelaDetalhesProps): JSX.Element => {
  const [carregando, setCarregando] = useState(true);
  const [notaFiscal, setNotaFiscal] = useState<any>();
  const [processando, setProcessando] = useState(false);
  const [venda, setVenda] = useState<Servicos>();
  const [load, setLoad] = useState(false);
  const [loadComponent, setLoadComponent] = useState(true);
  const [arrayServicos, setArrayServicos] = useState([]);
  const [erro, setError] = useState(false);
  const base64toBlob = useCallback((b64: string) => {
    const byteString = window.atob(b64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }, []);

  const downloadPdfNf = useCallback(
    async (chaveAcessso: string, lojaCnpj: string) => {
      try {
        setProcessando(true);
        const response = await axios.get(
          `/v1/parcelas/nota-fiscal/${chaveAcessso}/pdf?lojaCnpj=${lojaCnpj}`
        );
        const pdf = base64toBlob(response.data);
        window.open(URL.createObjectURL(pdf), '_blank');
        setProcessando(false);
      } catch (error) {
        setProcessando(false);
      }
    },
    [base64toBlob]
  );

  useEffect(() => {
    (async () => {
      try {
        if (parcela?.nfNumero && parcela?.idParcelaErp) {
          const response = await axios.get(
            `/v2/parcelas/nota-fiscal/${parcela.nfNumero}/${parcela.idParcelaErp}`,
            { withCredentials: true }
          );
          setNotaFiscal(response.data);
          setCarregando(false);
        }
        setCarregando(false);
      } catch (error) {
        setCarregando(false);
      }
    })();
  }, [parcela]);

  useEffect(() => {
    (async () => {
      try {
        setLoadComponent(true);
        const response = await getServicos(parseInt(parcela.idVenda, 10));
        // const response = await getServicos(10);
        setVenda(response);
        const filter = response.vendaServicos.filter(
          (servicos: any) => servicos.observacao !== 'PROTEJA MAIS MARTINELLO'
        );
        setArrayServicos(filter);
        setLoadComponent(false);

        if (filter.length > 0) {
          setLoad(true);
        } else {
          setLoad(false);
        }
      } catch (error) {
        setCarregando(false);
        setLoadComponent(false);
        setError(true);
      }
    })();
  }, [parcela.idVenda]);

  return (
    <div>
      <StyledDrawer anchor="right" onClose={onClose} open={open}>
        <ParcelaDetalhes
          dataVencimento={new Date(parcela.dataVencimento)}
          dataVenda={new Date(parcela.dataVenda)}
          idCarne={parcela.idCarne}
          idVenda={parcela.idVenda}
          lojaNome={parcela.lojaNome}
          numeroParcela={parcela.numeroParcela}
          onClose={onClose}
          valorDescontado={parcela.valorDescontado}
          valorJuros={parcela.valorJuros}
          valorOriginal={parcela.valorOriginal}
          valorTotal={parcela.valorTotal}
        />

        <Flex
          direction="column"
          style={{ padding: '0 0.5rem ', background: 'rgba(244,244,244,1)' }}
        >
          {carregando && (
            <Flex
              alignItems="center"
              direction="column"
              style={{ marginTop: '1rem' }}
            >
              <CircularProgress />
            </Flex>
          )}
          {notaFiscal && parcela.tipo !== 'P' && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col bg-white pl-3 pr-2 pt-3 rounded pb-2">
                  <Typography variant="subtitle2">
                    Número da nota fiscal
                  </Typography>
                  <Typography variant="body2">{parcela.nfNumero}</Typography>
                  <Typography variant="subtitle2">
                    Valor total da nota
                  </Typography>
                  <Typography variant="body2">
                    {toReal(notaFiscal.valorTotal)}
                  </Typography>
                </div>
                <Flex
                  direction="column"
                  // style={{ maxWidth: 320, paddingBottom: 5 }}
                >
                  <AccordionProduto
                    erro={erro}
                    itens={venda?.itens}
                    key={venda?.idVenda}
                    load={loadComponent}
                  />

                  {load && (
                    <Accordion
                      key={venda?.idVenda}
                      load={loadComponent}
                      vendaServico={arrayServicos}
                    />
                  )}

                  {/* <Typography variant="subtitle2">{i.descricao}</Typography>
                    <Typography variant="caption">
                    Quantidade: {i.quantidade}
                    </Typography>
                    <Typography variant="caption">
                    Valor Total: {toReal(i.valorTotal)}
                  </Typography> */}
                </Flex>
                <div className="flex flex-col bg-white pl-2 pr-2 p-2 rounded">
                  <ButtonExtended
                    color="primary"
                    loading={processando}
                    onClick={() =>
                      downloadPdfNf(notaFiscal.chave, parcela.lojaCnpj)
                    }
                    startIcon={<PictureAsPdfSharp />}
                    variant="text"
                  >
                    Baixar Nota Fiscal
                  </ButtonExtended>
                </div>
              </div>
          )}
          {parcela.tipo === 'P' && (
            <div className="max-w-xs  h-full">
              <p className="text-base text-center">
                Não é possivel visualizar detalhes desse produto, pois existe
                uma novação vinculada nesse contrato, para detalhes encontre a
                loja mais proxima
              </p>
            </div>
          )}
        </Flex>
      </StyledDrawer>
      {/* <Dialog onClose={fechaModal} open={openModal}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography color="primary" gutterBottom variant="h6">
              Resumo da nota fiscal
            </Typography>
            <IconButton aria-label="close" onClick={fechaModal} size="small">
              <CloseOutlined color="action" />
            </IconButton>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">
            Número da nota fiscal
          </Typography>
          <Typography gutterBottom variant="body2">
            {notaFiscal.numero}
          </Typography>

          <Typography variant="subtitle2">
            Valor total da nota
          </Typography>
          <Typography variant="body2">
            {toReal(notaFiscal.valorTotal)}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '16px 0' }}>Descrição</TableCell>
                  <TableCell>Qtd.</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  notaFiscal.itens?.map((i: any) => (
                    <TableRow key={i.cod}>
                      <TableCell style={{ padding: '16px 0' }}>{i.descricao}</TableCell>
                      <TableCell>{i.quantidade}</TableCell>
                      <TableCell>{toReal(i.valorTotal)}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Flex justifyContent="center" style={{ paddingTop: '1rem' }}>
            <ButtonExtended
              color="primary"
              loading={carregando}
              onClick={() => downloadPdfNf(parcela.nfChave, parcela.lojaCnpj)}
              startIcon={<PictureAsPdfSharp />}
              variant="text"
            >Nota Fiscal</ButtonExtended>
          </Flex>
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default DrawerParcelaDetalhes;
