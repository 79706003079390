import { useCallback, useState } from 'react';
import QRCode from 'react-qr-code';

import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { FileCopyOutlined, ScheduleOutlined } from '@material-ui/icons';

import { copy } from '../helpers/utils';
import Flex from './flex';

export default function Pix(pix: any): JSX.Element {
  const [copiado, setCopiado] = useState(false);
  const { codigoQr } = pix.pix

  const handleCopy = useCallback(() => {
    copy(codigoQr);
    setCopiado(true);
  }, [codigoQr]);

  return (
    <Card style={{ overflow: 'auto' }}>
      <CardContent>
        <Flex direction="column">
          <Typography gutterBottom variant="subtitle2">
            Escaneie esse código para pagar
          </Typography>
          <Typography variant="body2">
            <b>1.</b> Acesse seu internet banking ou app de pagamentos
          </Typography>
          <Typography variant="body2">
            <b>2.</b> Escolha pagar via PIX
          </Typography>
          <Typography variant="body2">
            <b>3.</b> Escaneie o seguinte código
          </Typography>
          {codigoQr ?
            <Flex justifyContent="center" style={{ padding: '1rem 0' }}>
              <QRCode size={180} value={codigoQr} />
            </Flex>
            : null}
          <Flex alignItems="center">
            <ScheduleOutlined style={{ marginRight: 5 }} />
            <Typography variant="body2">
              Pague e será creditado na hora
            </Typography>
          </Flex>
          <Divider style={{ margin: '1rem 0' }} />
          <Typography gutterBottom variant="subtitle2">
            Ou copie este código QR para fazer o pagamento
          </Typography>
          <Typography variant="body2">
            Escolha pagar via PIX pelo seu internet banking ou app de
            pagamentos.
            <br />
            Depois cole o seguinte código.
          </Typography>
          <Flex style={{ margin: '1rem 0', maxWidth: 504, width: '100%' }}>
            <div
              style={{
                border: '2px solid lightgray',
                borderRadius: 5,
                fontSize: 13,
                overflowWrap: 'anywhere',
                padding: '.5rem',
                textAlign: 'center',
                userSelect: 'none',
              }}
            >
              {codigoQr}
            </div>
          </Flex>
          <Flex justifyContent="center">
            <Button
              fullWidth
              onClick={handleCopy}
              startIcon={<FileCopyOutlined />}
              style={
                copiado
                  ? { backgroundColor: `#4cb050`, color: '#fff' }
                  : { color: '#444' }
              }
              variant="outlined"
            >
              {copiado ? 'Copiado com sucesso!' : 'Copiar Código'}
            </Button>
          </Flex>
        </Flex>
      </CardContent>
    </Card>
  );
}
