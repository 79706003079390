import { createContext, FC, useCallback, useMemo, useState } from 'react';

import axios from 'axios';

interface Usuario {
  readonly id: number;
  nome: string;
  cpfCnpj: string;
  pessoaJuridica: boolean;
}

interface UserContextData {
  clear: () => void;
  isAuthenticated: boolean;
  token: string | null;
  usuario: Usuario | null;
  setToken: (token: string) => void;
  setUsuario: (usuario: Usuario) => void;
  handleLoginCpf: (email: string, senha: string) => Promise<void>;
  handleLoginCnpj: (email: string, senha: string) => Promise<void>;
  handleGeraTokenParcela: () => Promise<void>;
}

const t = localStorage.getItem('token');
const u = localStorage.getItem('usuario')
  ? JSON.parse(localStorage.getItem('usuario') as string)
  : null;
const UserContext = createContext<UserContextData>({
  isAuthenticated: Boolean(t && u),
  token: t,
  usuario: u,
} as UserContextData);

const UserContextProvider: FC = ({ children }) => {
  const [usuario, setUsuarioState] = useState<Usuario | null>(u);
  const [token, setTokenState] = useState<string | null>(t);

  const clear = useCallback(() => {
    localStorage.clear();
    setTokenState(null);
    setUsuarioState(null);
  }, []);

  const setUsuario = useCallback((usu: Usuario): void => {
    localStorage.setItem('usuario', JSON.stringify(usu));
    setUsuarioState(usu);
  }, []);

  const setToken = useCallback((tok: string): void => {
    localStorage.setItem('token', tok);
    setTokenState(tok);
  }, []);

  const handleGeraTokenParcela = useCallback(async () => {
    await axios.get('/v2/parcelas/token', { withCredentials: true })
  }, [])

  const handleLoginCpf = useCallback(
    async (email, senha) => {
      const response = await axios.post('/v1/auth/login', { email, senha });
      // console.log(response);

      setToken(response.data.token);
      setUsuario({
        id: response.data.usuario.id,
        nome: response.data.usuario.nomeCompleto,
        cpfCnpj: response.data.usuario.cpf.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4'
        ),
        pessoaJuridica: false,
      });

      handleGeraTokenParcela()
    },
    [handleGeraTokenParcela, setToken, setUsuario]
  );

  const handleLoginCnpj = useCallback(async (cnpj, senha) => {
    const response = await axios.post('/v1/auth/login-pj', { cnpj, senha });

    setToken(response.data.token);
    setUsuario({
      id: parseInt(cnpj.replace(/[./-]/g, ''), 10),
      nome: 'Pessoa Jurídica',
      cpfCnpj: cnpj,
      pessoaJuridica: true,
    });

    handleGeraTokenParcela()

  }, [handleGeraTokenParcela, setToken, setUsuario]);
  const isAuthenticated = useMemo(() => Boolean(token && usuario), [token, usuario]);

  return (
    <UserContext.Provider
      value={{
        clear,
        isAuthenticated,
        token,
        usuario,
        setToken,
        setUsuario,
        handleLoginCpf,
        handleLoginCnpj,
        handleGeraTokenParcela
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider };
export default UserContext;
