import { Grid, Typography } from '@material-ui/core';
import { FavoriteOutlined } from '@material-ui/icons';
import styled from 'styled-components';

import { Container } from '../components';

const MyFooter = styled.footer`
  background-color: #e94646;
  /* border-start-start-radius: 25px; */
  /* border-start-end-radius: 25px; */
  box-shadow: 0 10px 20px rgb(0 0 0 / 53%), 0 6px 6px rgb(0 0 0 / 53%);
  color: #fff;
  margin-top: 1rem;
  padding: 8px 0 4px 0;
  text-align: center;
`;

const Footer = (): JSX.Element => (
  <MyFooter>
    <Container>
      <Grid container justifyContent="space-between">
        <Grid item sm="auto" xs={12}>
          <Typography variant="body2">
            Copyright &copy; | Todos os Direitos Reservados.
          </Typography>
        </Grid>
        <Grid item sm="auto" xs={12}>
          <small
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Desenvolvido com <FavoriteOutlined htmlColor="#fff" /> por&nbsp;
            <a
              href="https://labs.martinello.com.br/"
              rel="noreferrer"
              style={{ fontSize: 14 }} target="_blank"
            >
              MartiLabs
            </a>
          </small>
        </Grid>
      </Grid>
    </Container>
  </MyFooter>
);

export default Footer;
