/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import './Accordion.css';
import { CircularProgress } from '@material-ui/core';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import iconervice from '../assets/icones/serviços.png';
import { toReal } from '../helpers/utils';
import Flex from './flex';

interface Props {
  vendaServico: [] | any;
  load: boolean;
}

const Accordion = ({ vendaServico, load }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-container">
      <div
        className="accordion-header items-center justify-between"
        onClick={toggleAccordion}
      >
        <div className="flex gap-2 items-center">
          <img alt="icon" className="flex h-6" src={iconervice} />
          <span className="text-black font-bold text-lg">Serviços</span>
        </div>
        <span>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
      </div>
      {load && (
        <Flex
          alignItems="center"
          direction="column"
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <CircularProgress />
        </Flex>
      )}
      {isOpen && (
        <div>
          {vendaServico?.map((e: any) => (
            <div className="accordion-body" key={e.idVendaServico}>
              <span>{e.observacao}</span>
              <div className="flex justify-between">
                <span>{e.tempoCobertura} Meses</span>
                <span>{toReal(e.valorTotalAVista)}</span>
              </div>
            </div>
          ))}
         </div>
      )}
    </div>
  );
};

export default Accordion;
