import { useCallback, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';

import axios, { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { novaSolicitacao } from '../../api/lgpd/Lgpd';
import { Carregando } from '../../components';

interface DadosUsuario {
  id?: string;
  email: string;
  nomeCompleto: string;
  cpf: string;
  OpcaoLGPD: string[];
  telefone: string;
  retificacaoOpcao: string;
  formaDeContato: string;
}

const Lgpd = (): JSX.Element => {
  const [user, setUser] = useState<DadosUsuario>({} as DadosUsuario);
  const [checado, setChecado] = useState('');
  const [checadoInfo, setChecadoInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [retificacao, setRetificacao] = useState(false);
  const [editandoWhats, setEditandoWhats] = useState(false);
  const [editandoEmail, setEditandoEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [hideMensagem, setHideMensagem] = useState(false);
  const [textMensagem, setTextMensagem] = useState('');

  const verificaState = useCallback(() => {
    if (checado === 'Retificacao') {
      setRetificacao(true);
    }
  }, [checado]);

  const handleEditWhats = useCallback(() => {
    setEditandoEmail(false);
    setEditandoWhats(true);
  }, []);

  const handleEditEmail = useCallback(() => {
    setEditandoWhats(false);
    setEditandoEmail(true);
  }, []);

  const query = useQuery('list-user', async () => {
    const response: AxiosResponse = await axios.get('/v1/minha-conta');
    setUser(response.data);
    setEmail(response.data.email);
  });

  const valoresIniciais = {
    email: user.email,
    nomeCompleto: user.nomeCompleto,
    cpf: user.cpf,
    OpcaoLGPD: [],
    retificacaoOpcao: user.retificacaoOpcao,
    formaDeContato: user.formaDeContato,
    telefone: user.telefone,
  };

  const onSubmit = useCallback(
    async (values: DadosUsuario) => {
      setIsLoading(true);
      if (checado === 'Retificacao') {
        if (
          values.retificacaoOpcao === undefined ||
          values.retificacaoOpcao.length < 1
        ) {
          enqueueSnackbar('Campo Retificação é obrigatório', {
            variant: 'error',
          });
          return;
        }
      }

      try {
        const response = await novaSolicitacao(
          values,
          checado,
          user.cpf,
          checadoInfo,
          textMensagem !== '' && textMensagem !== '<p><br></p>'
            ? textMensagem
            : undefined
        );

        setIsLoading(false);

        enqueueSnackbar('Solicitação enviada com sucesso!', {
          variant: 'success',
        });
        document.location.href = `/dados-cliente/lgpd-solicitacao-detalhes/${
          response.id
        }/${true}`;
        // navigate('/dados-cliente/lgpd-solicitacao-detalhes');
      } catch (error) {
        console.log(error);

        if (
          error.response.data.message ===
          '6 ALREADY_EXISTS: Cliente tem uma solicitação do mesmo tipo em andamento'
        ) {
          enqueueSnackbar(
            'Já existe uma solicitação do mesmo tipo em andamento',
            {
              variant: 'error',
            }
          );
          setIsLoading(false);
          return;
        }
        enqueueSnackbar('Erro ao solicitar!', {
          variant: 'error',
        });

        setIsLoading(false);
      }
    },
    [checado, enqueueSnackbar, user.cpf, checadoInfo, textMensagem]
  );

  if (query.isLoading) {
    return (
      <div className="flex lg:pl-[300px] lg:pt-[100px] mx-auto w-full min-h-[300px] justify-center">
        <Carregando />
      </div>
    );
  }

  return (
    <div className="flex flex-col  pt-0 ">
      <Formik initialValues={valoresIniciais} onSubmit={onSubmit}>
        {({ handleChange, isSubmitting, values }) => (
          <Form>
            <div className="flex flex-col mx-auto w-full  justify-center">
              <div className="flex flex-col mt-5 mb-5 gap-4 text-base">
                <div className="flex flex-col">
                  <span className="text-gray-900  block mb-1 text-sm font-medium">
                    Email
                  </span>
                  <div>
                    <div className="block w-full max-w-lg p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      {email}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-900  block mb-1 text-sm font-medium">
                    Nome Completo
                  </span>
                  <span className="block w-full p-2.5 max-w-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {values.nomeCompleto}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-900  block mb-1 text-sm font-medium">
                    CPF
                  </span>
                  <span className="block max-w-[240px] p-2.5  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {values.cpf.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})$/g,
                      '$1.$2.$3-$4'
                    )}
                  </span>
                </div>
              </div>

              <fieldset>
                <legend className="text-[#CA181A] block mb-1 text-lg font-medium">
                  Receba as informações via:
                </legend>
                <div className="mt-4 space-y-4 mb-4">
                  <div className="flex flex-col gap-2">
                    <label className="flex items-center" htmlFor="Email">
                      <input
                        className="h-[18px]  w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        id="Email"
                        name="OpcaoEnvioInfo"
                        onChange={() => setChecadoInfo('email')}
                        onClick={handleEditEmail}
                        type="radio"
                        value="Email"
                      />
                      <p
                        className={`${
                          checadoInfo === 'email'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Email
                      </p>
                    </label>
                    <div className="flex w-full">
                      {editandoEmail && (
                        <input
                          className={` "block w-full max-w-lg p-2.5  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 max-w-[240px] dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                            editandoEmail ? 'bg-gray-50 ' : 'bg-blue-50'
                          }`}
                          disabled={!editandoEmail}
                          id="email"
                          key={values.id}
                          onChange={handleChange}
                          placeholder="E-mail"
                          required
                          type="text"
                          value={values.email}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex gap-2 flex-col">
                    <label
                      className="flex items-center"
                      htmlFor="WhatsApp/Telefone"
                    >
                      <input
                        className="h-[18px]  w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        id="WhatsApp/Telefone"
                        name="OpcaoEnvioInfo"
                        onChange={() => setChecadoInfo('whatsapp')}
                        onClick={handleEditWhats}
                        type="radio"
                        value="WhatsApp/Telefone"
                      />
                      <p
                        className={`${
                          checadoInfo === 'whatsapp'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        WhatsApp/Telefone
                      </p>
                    </label>
                    <div className="flex flex-col">
                      {editandoWhats && (
                        <ReactInputMask
                          className={` "  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 max-w-[240px] dark:focus:ring-blue-500 dark:focus:border-blue-500 " ${
                            editandoWhats ? 'bg-gray-50 ' : 'bg-blue-50'
                          }`}
                          id="whatsApp"
                          mask="+55(99) 99999-9999"
                          onChange={handleChange}
                          placeholder="WhatsApp/Telefone"
                          required={editandoWhats}
                          type="text"
                          value={values.telefone}
                        />
                      )}
                      {checadoInfo === 'WhatsApp/Telefone' && (
                        <span className="block pt-1 text-sm font-medium text-red-600 ">
                          Este campo é necessário *
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset className="flex w-full">
                <legend className="text-[#CA181A] block mb-1 text-lg font-medium">
                  Selecione um dos motivos abaixo:
                </legend>
                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <label className="flex" htmlFor="ConfirmaTratamento">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        id="ConfirmaTratamento"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('ConfirmaTratamento')}
                        type="radio"
                        value="ConfirmaTratamento"
                      />
                      <p
                        className={`${
                          checado === 'ConfirmaTratamento'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Confirmação da existência de tratamento de dados
                        pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex " htmlFor="InfoSimplificada">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="InfoSimplificada"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('InfoSimplificada')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'InfoSimplificada'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Informação simplificada de dados pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="InfoCompleta">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="InfoCompleta"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('InfoCompleta')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'InfoCompleta'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Informação completa de dados pessoais
                      </p>
                    </label>
                  </div>

                  <div className=" flex-col max-h-full items-center">
                    <label className="flex" htmlFor="Retificacao">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="Retificacao"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('Retificacao')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'Retificacao'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Retificação de dados pessoais
                      </p>
                    </label>

                    <div
                      className={` ${
                        checado === 'Retificacao'
                          ? 'flex p-2 gap-1 flex-col'
                          : 'hidden'
                      }`}
                    >
                      <span className="text-[12px] font-bold">
                        Qual informação você gostaria de retificar?
                      </span>
                      <div className="flex flex-col">
                        <input
                          className={`block w-full p-2.5 max-w-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            checado === 'Retificacao'
                              ? 'required:border-red-400'
                              : ''
                          } `}
                          id="retificacaoOpcao"
                          onChange={handleChange}
                          placeholder="ex: Nome"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="Exclusao">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="Exclusao"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('Exclusao')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'Exclusao'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Anonimização, bloqueio ou eliminação dos dados pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="Portabilidade">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="Portabilidade"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('Portabilidade')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'Portabilidade'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Portabilidade dos dados pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex">
                    <label className="flex" htmlFor="InfoCompartilhamento">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="InfoCompartilhamento"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('InfoCompartilhamento')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'InfoCompartilhamento'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Informações sobre o compartilhamento dos dados pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="InfoDecisao">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="InfoDecisao"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('InfoDecisao')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'InfoDecisao'
                            ? 'px-2 text-gray-900 block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Informações sobre decisões automatizadas
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="RevogacaoConsent">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="RevogacaoConsent"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('RevogacaoConsent')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'RevogacaoConsent'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Revogação de consentimento para tratamento de dados
                        pessoais
                      </p>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <label className="flex" htmlFor="InfoConsequencia">
                      <input
                        className="h-[18px] mt-1 w-[18px] min-w-[18px] min-h-[18px] border-gray-300 text-indigo-600 focus:ring-red-500"
                        id="InfoConsequencia"
                        name="OpcaoLGPD"
                        onChange={() => setChecado('InfoConsequencia')}
                        type="radio"
                      />
                      <p
                        className={`${
                          checado === 'InfoConsequencia'
                            ? 'px-2 text-gray-900  block text-base font-normal'
                            : 'px-2 text-gray-500  block text-base font-normal'
                        }`}
                      >
                        Informações sobre quais as consequências negativas do
                        não fornecimento de consentimento para tratamento de
                        dados pessoais
                      </p>
                    </label>
                  </div>
                </div>
              </fieldset>
              <div className="w-full flex flex-col">
                <button
                  className="text-start mt-5 text-[#CA181A] underline text-base cursor-pointer"
                  onClick={() => setHideMensagem(!hideMensagem)}
                  type="button"
                >
                  Adicionar mensagem
                </button>
                {hideMensagem && (
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-[100%] h-56 md:h-96 ">
                    <div className="flex flex-col w-full h-full ">
                      <ReactQuill
                        className="flex flex-col h-40  md:h-80"
                        id="editor"
                        onChange={(e: string) => setTextMensagem(e)}
                        value={textMensagem}
                      />
                    </div>
                  </div>
                )}
                <div className="mx-auto py-7">
                  <button
                    className={`flex rounded bg-[#B80008] p-2 text-white
                  hover:bg-red-800 text-center justify-center items-center gap-4 font-bold lg:w-[250px] ${
                    isLoading ? 'opacity-50  bg-gray-400' : ''
                  }`}
                    disabled={isLoading}
                    type="submit"
                  >
                    Enviar Solicitação
                    {isLoading ? <FaSpinner className="animate-spin" /> : ''}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Lgpd;
