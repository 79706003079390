import styled from 'styled-components';

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  padding-top: 2em;
  text-transform: uppercase;

  &:after {
    content: "";
    display: block;
    width: 15em;
    max-width: 70%;
    border-bottom: 0.3em solid red;
    margin: 1em 0;
  }
`;

export default Title;
