import Carousel from 'react-material-ui-carousel';
import { useNavigate } from 'react-router-dom';

import bannerApp from '../../assets/banner-app-3.png';
import bannerTopo02Laptop from '../../assets/images/banner3_laptop.png';
// import bannerApp from '../../assets/images/bannerAPP.png';
import bannerTopo01 from '../../assets/images/bannerTop3.png';
import bannerTopo02 from '../../assets/images/bannerTop4.png';
import cidade from '../../assets/images/cidade.png';
import crediario from '../../assets/images/crediario.png';
import distribuicao from '../../assets/images/distribuicao.png';
import eCommerce from '../../assets/images/eCommerce.png';
import loja from '../../assets/images/loja.png';
import bannerMobile from '../../assets/images/Mobile01.png';
import bannerMobile2 from '../../assets/images/Mobile03.png';
import trabalhe from '../../assets/images/trabalhe.png';
import FloatingButton from '../../components/floatingButton';
import Contato from './contato';
import {
  AcessoRapido,
  Container,
  DivImg,
  GridLojistica,
  IconeAcessoRapido,
  IconeLogistica,
  ImagemResponsive,
  ImagemResponsiveBanner,
  ImgIcone,
  LinkAcessoRapido,
  Section,
  SectionVideo,
  Title
} from './styles';

const Home = (): JSX.Element => {
  const navigate = useNavigate();

  const isMobile = () => {
    if (window.innerWidth <= 800 && window.innerHeight <= 900) {
      return true;
    }
    return false;
  };

  const isLaptop = () => {
    if (window.innerWidth <= 1400 && window.innerHeight <= 1000) {
      return true;
    }
    return false;
  };

  return (
    <>
      {isMobile() === true && (
        <DivImg>
          <Carousel className="carouselWrap" indicators={false} interval={6000}>
            <ImagemResponsive alt="banner Eletromóveis Martinello" src={bannerMobile} />
            <ImagemResponsive
              alt="banner Eletromóveis Martinello"
              src={bannerMobile2}
            />
          </Carousel>
        </DivImg>
      )}
      {isMobile() === false && (
        <DivImg>
          <Carousel className="carouselWrap" indicators={false} interval={6000}>
            <ImagemResponsive
              alt="banner Eletromóveis Martinello"
              src={isLaptop() ? bannerTopo02Laptop : bannerTopo02}
            />
            <ImagemResponsive
              alt="banner Eletromóveis Martinello"
              src={bannerTopo01}
            />
          </Carousel>
        </DivImg>
      )}
      <Container>
        <Section>
          <Title style={{ textAlign: 'center' }}>
            Acesso<span> rápido</span>
          </Title>
          <AcessoRapido>
            <LinkAcessoRapido
              onClick={() => navigate('../parcelas')}
              target="_blank"
            >
              <IconeAcessoRapido>
                <ImgIcone alt="Ícone representando um cartão" src={crediario} />
              </IconeAcessoRapido>
              <div>
                <h3>Crediário</h3>
                <p>Pagamento de parcelas do carnê Martinello</p>
              </div>
            </LinkAcessoRapido>
            <LinkAcessoRapido
              href="https://www.martinello.com.br/"
              target="_blank"
            >
              <IconeAcessoRapido>
                <ImgIcone alt="Ícone de um ponteiro do mouse" src={eCommerce} />
              </IconeAcessoRapido>
              <div>
                <h3>E-commerce</h3>
                <p>Compre da Martinello através do SITE ou APP</p>
              </div>
            </LinkAcessoRapido>
            <LinkAcessoRapido
              href="https://trabalheconosco.vagas.com.br/martinello"
              target="_blank"
            >
              <IconeAcessoRapido>
                <ImgIcone alt="Icone representando uma maleta" src={trabalhe} />
              </IconeAcessoRapido>
              <div>
                <h3>Trabalhe Conosco</h3>
                <p>Veja as nossas vagas disponíveis</p>
              </div>
            </LinkAcessoRapido>
          </AcessoRapido>
        </Section>
        {/* </Container> */}
        <SectionVideo>
          <iframe
            height="80%"
            src="https://www.youtube.com/embed/3bf-D2K_Kew"
            style={{ border: 'none' }}
            title="Portal do Cliente"
            width="100%"
          />
        </SectionVideo>
        {/* <Container> */}
        {/* Nossa Historia Section */}
        {/* <Section>
          <TitleWithImage>
            <img alt="" src={coracoes} />
            <Title style={{ textAlign: 'center' }}>
              Nossa <span>História</span>
            </Title>
          </TitleWithImage>
          <div>
            <TextoDestacado>
              Tudo começou em 1989, no município de Lucas do Rio Verde - MT, com
              uma loja modesta, onde faltava espaço, mas sobrava a vontade de se
              realizar algo grandioso.
            </TextoDestacado>
            <TextPadrao>
              Hoje, a Martinello é a maior empresa de varejo genuinamente
              mato-grossense com lojas espalhadas por todo o Estado e logística
              realizada com frota própria e centros de distribuição
              estrategicamente instalados para atender a demanda com rapidez e
              eficiência.
            </TextPadrao>
          </div>
        </Section> */}
        <Section>
          <GridLojistica>
            <IconeLogistica>
              <img alt="" src={loja} />
              <p>105 Lojas Físicas</p>
            </IconeLogistica>
            <IconeLogistica>
              <img alt="" src={distribuicao} />
              <p>
                7 Centros de Distribuição
              </p>
            </IconeLogistica>
            {/* <IconeLogistica>
              <p>+ de 100 veículos</p>
            </IconeLogistica> */}
            <IconeLogistica>
              <img alt="" src={cidade} />
              <p>70 Cidades Atendidas</p>
            </IconeLogistica>
          </GridLojistica>
          {isMobile() ? (
            <a href="http://onelink.to/by3296" rel="noreferrer" target="_blank">
              <ImagemResponsiveBanner
                alt="conheça nosso app, disponível para Android e iOS"
                src={bannerApp}
              />
            </a>
          ) : (
            <ImagemResponsiveBanner
              alt="conheça nosso app, disponível para Android e iOS"
              src={bannerApp}
            />
          )}
        </Section>
        <Section>
          <Contato />
        </Section>
      </Container>

    </>
  );
};

export default Home;
