import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { List, Paper, Tab, Tabs, Typography } from '@material-ui/core';

import imagemBoleto from '../../assets/images/atencao-boleto.png';
import { Container, Flex } from '../../components';
import UserContext from '../../contexts/user';
import ParcelasAbertas from './parcelas-abertas';
import ParcelasPagas from './parcelas-pagas';
import './styles/Modal.css';

export interface ParcelaApi {
  idParcelaErp: string;
  idVenda: string;
  idCarne: string;
  lojaCnpj: string;
  lojaNome: string | null;
  nfNumero: number;
  nfChave: string;
  numeroParcela: number;
  dataVenda: string;
  dataVencimento: string;
  valorOriginal: number;
  valorJuros: number;
  valorDescontado: number;
  valorTotal: number;
  status: string;
  tipo: string;
}

export interface RecebimentoApi {
  boleto: any;
  identificador: string;
  tipo: string;
  valorTotal: number;
  valorPago: number;
  dataVencimento: string;
  dataPagamento: string;
  parcelas: ParcelaApi[] | null;
  pix: any;
}

const TabPanel: FC<{ index: number; value: number }> = ({
  children,
  index,
  value,
}) => <div hidden={value !== index}>{value === index && children}</div>;

const Parcelas = () => {
  const [value, setValue] = useState(0);

  const [isModalVisibility, setIsModalVisibility] = useState(true);
  const [isModalRead, setIsModalRead] = useState(
    localStorage.getItem('isModalRead') === 'true'
  );

  const handleCloseModal = () => {
    setIsModalRead(true);
    setIsModalVisibility(false);
    localStorage.setItem('isModalRead', 'true');
  };

  const { isAuthenticated, usuario, handleGeraTokenParcela } =
    useContext(UserContext);

  const handleChange = useCallback((e: ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    handleGeraTokenParcela();
  }, [handleGeraTokenParcela]);

  // if (!isAuthenticated) {
  //   return <Navigate to="/login?redir=/parcelas" />;
  // }

  return (
      <div className="flex pt-5">
        <Container className="mt-4">
          <Paper>
            <Tabs
              centered
              indicatorColor="primary"
              onChange={handleChange}
              textColor="primary"
              value={value}
            >
              <Tab label="Em aberto" />
              <Tab label="Pagas" />
            </Tabs>
          </Paper>
          <Flex justifyContent="flex-end" style={{ paddingTop: '.5rem' }}>
            <Typography
              style={{ textTransform: 'uppercase' }}
              variant="caption"
            >
              {usuario?.nome} - CPF/CNPJ:{' '}
              {!usuario?.pessoaJuridica
                ? usuario?.cpfCnpj.replace(
                    /(\d{3}\.)(\d{3}\.)(\d{3}-)(\d{2})$/g,
                    '$1***.***-$4'
                  )
                : usuario?.cpfCnpj.replace(
                    /^(\d{2}\.)(\d{3}\.)(\d{3}\/\d{4}-)(\d{2})$/g,
                    '$1***.***/****-$4'
                  )}
            </Typography>
          </Flex>
          <TabPanel index={0} value={value}>
            <ParcelasAbertas />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <ParcelasPagas />
          </TabPanel>
        </Container>
      </div>
  );
};

export default Parcelas;
