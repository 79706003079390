/* eslint-disable max-len */
import { useCallback, useState } from 'react';

import { CardContent, Divider, Drawer, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import styled from 'styled-components';

import type { ParcelaApi } from '.';
import { Boleto, Flex, Parcela, Pix } from '../../components';
import { toReal } from '../../helpers/utils';
import DrawerParcelaDetalhes from './drawer-parcela-detalhes';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 370 !important;
  }

  @media (max-width: 420px) {
    .MuiDrawer-paper {
      width: 93% !important;
    }
  }
`;

interface DrawerRecebimentoProps {
  boleto?: any | undefined;
  dataPagamento?: Date | undefined;
  onClose: () => void;
  open: boolean;
  parcelas: any[] | null;
  pix?: any | undefined;
  tipo: string;
  valorPago: number;
}

const DrawerRecebimento = ({
  boleto,
  dataPagamento,
  onClose,
  open,
  parcelas,
  pix,
  tipo,
  valorPago,
}: DrawerRecebimentoProps) => {
  const [openDrawerParcelaDetalhes, setOpenDrawerParcelaDetalhes] =
    useState(false);
  const [parcelaSelecionada, setParcelaSelecionada] = useState(
    {} as ParcelaApi
  );

  const mostraParcelaDetalhes = useCallback((parcela: ParcelaApi) => {
    setParcelaSelecionada(parcela);
    setOpenDrawerParcelaDetalhes(true);
  }, []);

  return (
    <>
      <StyledDrawer anchor="right" onClose={onClose} open={open}>
        <CardContent>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography color="primary" gutterBottom variant="h6">
              Detalhes do pagamento
            </Typography>
            <button
              aria-label="Fechar"
              onClick={onClose}
              style={{ border: 'none', background: 'none' }}
              type="button"
            >
              <CloseOutlined />
            </button>
          </Flex>

          {parcelas && (
            <>
              <Typography gutterBottom variant="body2">
                Quantidade de parcelas: {parcelas.length}
              </Typography>

              <Typography gutterBottom variant="body2">
                Total das parcelas: {toReal(valorPago)}
              </Typography>
            </>
          )}
          {!parcelas && (
            <Typography gutterBottom variant="body2">
              Valor total: {toReal(valorPago)}
            </Typography>
          )}
          {dataPagamento && (
            <Typography variant="body2">
              Pago em: {format(dataPagamento, 'dd/MM/yyyy')}
            </Typography>
          )}

          {parcelas && (
            <>
              <Divider style={{ margin: '1rem 0' }} />
              <div style={{ marginBottom: '1rem' }}>
                {boleto?.codigoBarras && <Boleto boleto={boleto} />}
                {pix && <Pix pix={pix} />}
              </div>
              {parcelas.map((p) => (
                <Parcela
                  data={new Date(p.dataVencimento)}
                  idVenda={p.idVenda}
                  key={p.idParcelaErp}
                  numeroParcela={p.numeroParcela}
                  onClick={() => mostraParcelaDetalhes(p)}
                  status={p.status}
                  tipo={tipo}
                  valor={p.valorTotal}
                />
              ))}
            </>
          )}
        </CardContent>
      </StyledDrawer>
      {openDrawerParcelaDetalhes && (
        <DrawerParcelaDetalhes
          onClose={() => setOpenDrawerParcelaDetalhes(false)}
          open
          parcela={parcelaSelecionada}
        />
      )}
    </>
  );
};

DrawerRecebimento.defaultProps = {
  boleto: undefined,
  dataPagamento: undefined,
  pix: undefined,
};

export default DrawerRecebimento;
