import React, { useState } from 'react';

import { Grid, Popover, TextField, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import CodVendasImg from '../../assets/images/codVendas.png'
import { ButtonExtended, PasswordCustomField, TextFieldMask } from '../../components';
import theme from '../../constants/theme';

const LinkText = styled.span`
  font-size: 0.8rem;
  padding-left: 2px;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  cursor: pointer;
`
interface ICadastroFormularioPj {
  email: string;
  cnpj: string;
  nome: string;
  senha: string;
  telefone: string;
  codigoVenda: string;
}

interface CadastroFormularioPjProps {
  onSubmit: (
    values: ICadastroFormularioPj,
    formikHelpers: FormikHelpers<any>
  ) => void;
}

const initialValues = {
  nome: '',
  email: '',
  cnpj: '',
  senha: '',
  telefone: '',
  codigoVenda: '',
};

const CadastroFormularioPj = ({
  onSubmit,
}: CadastroFormularioPjProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const idPopOverCodVenda = open ? 'codVendaPop' : undefined;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleChange, isSubmitting, values }) => (
        <Form>
          <Typography style={{ padding: '1rem 0 0 0' }} variant="h5">
            <b>Dados da Empresa</b>
          </Typography>
          <Grid container spacing={1} style={{ padding: ' 1rem 0 0 0' }}>
            <Grid item lg={6} style={{ paddingTop: '0.5rem' }}
              xs={12}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                onChange={handleChange}
                placeholder="E-mail"
                required
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} style={{ paddingTop: '0.5rem' }}
              xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                onChange={handleChange}
                placeholder="nome"
                required
                type="text"
                value={values.nome}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4} sm={6}
              style={{ paddingTop: '0.5rem' }} xs={12}>
              <TextFieldMask
                fullWidth
                handleChange={handleChange}
                label="CNPJ"
                mask='99.999.999/9999-99'
                name="cnpj"
                placeholder="CNPJ"
                required
                type="text"
                value={values.cnpj}
              />
            </Grid>
            <Grid item lg={4} sm={6}
              style={{ paddingTop: '0.5rem' }} xs={12}>
              <TextFieldMask
                fullWidth
                handleChange={handleChange}
                label="Telefone"
                mask='(99) 99999-9999'
                name="telefone"
                placeholder="Telefone"
                required
                type="tel"
                value={values.telefone}
              />
            </Grid>
            <Grid item lg={4} sm={6}
              style={{ paddingTop: '0.5rem' }} xs={12}>
              <TextFieldMask
                fullWidth
                handleChange={handleChange}
                label="Código de Venda"
                mask='99999999999'
                name="codigoVenda"
                placeholder="Código de Venda"
                required
                type="text"
                value={values.codigoVenda}
              />
              <LinkText aria-describedby={idPopOverCodVenda} onClick={handleClick}>Onde encontrar o código de venda?</LinkText>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }} id={idPopOverCodVenda}
                onClose={handleClose} open={open}>
                <img alt="onde encontrar seu codigo da venda?" src={CodVendasImg} style={{maxWidth: '40rem'}}/>
              </Popover>
            </Grid>
            <Grid item lg={6} sm={6}
              style={{ paddingTop: '0.5rem' }} xs={12}>
              <PasswordCustomField
                handleChange={handleChange}
                id='senha-field'
                name="senha"
                value={values.senha}
              />
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '1rem 0',
              }}
              xs={12}
            >
              <ButtonExtended
                color="secondary"
                loading={isSubmitting}
                style={{ color: 'white' }}
                type="submit"
                variant="contained"
              >
                Cadastrar
              </ButtonExtended>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
};

export default CadastroFormularioPj;
