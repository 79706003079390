import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import { Flex } from './components';
import FloatingButton from './components/floatingButton';
import GlobalContext from './contexts';
import Footer from './layout/footer';
import Header from './layout/header';
import NotFound from './modules/404';
import Cadastro from './modules/cadastro';
import AlterarCadastro from './modules/cadastro/alterar-cadastro';
import DadosCliente from './modules/cadastro/layout-cadastro';
import CanaisDigitais from './modules/canais-digitais';
import Home from './modules/home';
import Lgpd from './modules/lgpd/lgpd';
import SolicitacaoDetalhe from './modules/lgpd/lgpd-solicitacao-detalhes';
import LgpdInicial from './modules/lgpd/lgpd-tela-inicial';
import Login from './modules/login';
import Logout from './modules/logout';
import Parcelas from './modules/parcelas';
import Privacidade from './modules/Privacidade/privacidade.index';
import Seguros from './modules/seguro/seguros';
import PrivateRoute from './Routes/PrivateRoute';
import 'react-quill/dist/quill.snow.css';

const App = (): JSX.Element => {
  const queryCliente = new QueryClient();
  return (
    <GlobalContext>
      <QueryClientProvider client={queryCliente}>
        <BrowserRouter>
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{
              minHeight: '100vh',
              backgroundColor: '#fff',
              boxSizing: 'border-box',
            }}
          >
            <Header />
            <main style={{ flex: 3, paddingTop: 80 }}>
              <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<CanaisDigitais />} path="/canais-digitais" />
                <Route element={<Login />} path="/login" />
                <Route element={<Login />} path="/login/:redir" />
                <Route element={<Login />} path="/login/:redir/:redir2" />
                <Route
                  element={
                    <PrivateRoute>
                      {' '}
                      <Parcelas />{' '}
                    </PrivateRoute>
                  }
                  path="/parcelas"
                />
                {/* <Route element={<Parcelas />} path="/parcelas" /> */}
                <Route element={<Cadastro />} path="/cadastro/:isCnpj" />
                <Route
                  element={
                    <PrivateRoute>
                      <DadosCliente />
                    </PrivateRoute>
                  }
                  path="/dados-cliente"
                >
                  <Route
                    element={<AlterarCadastro />}
                    path="alterar-cadastro"
                  />
                  <Route element={<LgpdInicial />} path="lgpd" />
                  <Route element={<Lgpd />} path="lgpd-nova-solicitacao" />
                  <Route element={<SolicitacaoDetalhe />} path="lgpd-solicitacao-detalhes/:id/:view" />
                  <Route element={<Seguros />} path="seguros" />
                  <Route element={<Privacidade />} path="privacidade" />
                </Route>
                <Route
                  element={<Navigate to="/dados-cliente/seguros" />}
                  path="/seguros"
                />
                <Route
                  element={<Navigate to="/dados-cliente/alterar-cadastro" />}
                  path="/alterar-cadastro"
                />
                <Route
                  element={<Navigate to="/lgpd-nova-solicitacao" />}
                  path="/lgpd-nova-solicitacao"
                />
                <Route
                  element={<Navigate to="/dados-cliente/lgpd" />}
                  path="/lgpd"
                />
                <Route
                  element={<Navigate to="/dados-cliente/privacidade" />}
                  path="/privacidade"
                />
                <Route
                  element={<Navigate to="/lgpd-solicitacao-detalhes/:id/:view" />}
                  path="/lgpd-solicitacao-detalhes/:id/:view"
                />
                <Route
                  element={<Navigate to="/cadastro/user=:isCnpj" />}
                  path="/cadastro/user=:isCnpj"
                />
                <Route element={<Logout />} path="/logout" />
                <Route element={<NotFound />} path="*" />
              </Routes>
            </main>
            <FloatingButton />
            <Footer />
          </Flex>
        </BrowserRouter>
      </QueryClientProvider>
    </GlobalContext>
  );
};
export default App;
