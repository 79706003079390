import { useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
// import * as Yup from 'yup';

import {
  ButtonExtended,
  PasswordCustomField,
  TextFieldMask,
} from '../../components';
import theme from '../../constants/theme';

interface ICadastroFormulario {
  email: string;
  cpf: string;
  nomeCompleto: string;
  dataNascimento?: string | null;
  sexo: string;
  senha: string;
  senhaConfirmacao: string;
  telefonePrincipalDDD: string;
  telefonePrincipal: string;
  telefoneSecundarioDDD?: string | null;
  telefoneSecundario?: string | null;
  // nomeDestinatario: string;
  cep: string;
  cidade: string;
  estado: string;
  bairro: string;
  endereco: string;
  numero: string;
  complemento?: string | null;
  referencia?: string | null;
  zonaRural: boolean;
  newsletter: boolean;
}

interface CadastroFormularioProps {
  onSubmit: (
    values: ICadastroFormulario,
    formikHelpers: FormikHelpers<any>
  ) => void;
}

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('preencha com um e-mail valido')
//     .required('E-mail é necessário'),
//   cpf: Yup.string()
//     .required('CPF é necessário')
//     .trim()
//     .matches(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/, 'CPF não é valido'),
//   senha: Yup.string()
//     .required('Por favor, digite uma senha')
//     .matches(
//       /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
//     ),
//   senhaConfirmacao: Yup.string()
//     .required('Por favor, confirme sua senha')
//     .oneOf([Yup.ref('senha'), null], 'Senhas não são iguais'),
// });

const initialValues = {
  email: '',
  cpf: '',
  nomeCompleto: '',
  dataNascimento: null,
  sexo: '',
  senha: '',
  senhaConfirmacao: '',
  telefonePrincipalDDD: '',
  telefonePrincipal: '',
  telefoneSecundarioDDD: null,
  telefoneSecundario: null,
  // nomeDestinatario: '',
  cep: '',
  cidade: '',
  estado: '',
  bairro: '',
  endereco: '',
  numero: '',
  complemento: null,
  referencia: null,
  zonaRural: false,
  newsletter: false,
};

const TypographyLink = styled(Typography)`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${theme.palette.primary.main};
  }

  a:visited {
    color: black;
  }

  a:hover {
    color: ${theme.palette.primary.main};
  }
`;

const CadastroFormulario = ({
  onSubmit,
}: CadastroFormularioProps): JSX.Element => {
  const [errorCep, setErrorCep] = useState(false);

  function onBlurCep(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    const { value } = e.target;

    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      return;
    }

    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.data)
      .then((data) => {
        if (data.erro && data.erro === true) {
          setErrorCep(true);
          setFieldValue('cidade', '');
          setFieldValue('estado', '');
          setFieldValue('endereco', '');
          setFieldValue('bairro', '');
        } else {
          setFieldValue('cidade', data.localidade ? data.localidade : '');
          setFieldValue('estado', data.uf ? data.uf : '');
          setFieldValue('endereco', data.logradouro ? data.logradouro : '');
          setFieldValue('bairro', data.bairro ? data.bairro : '');
          setErrorCep(false);
        }
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      // validationSchema={validationSchema}
    >
      {({
        handleChange,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <Form>
          <Typography style={{ padding: '1rem 0 0 0' }} variant="h5">
            <b>Dados Pessoais</b>
          </Typography>
          <Grid container style={{ flexGrow: 1, padding: ' 1rem 0 0 0' }}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <TextField
                  error={Boolean(errors.email)}
                  fullWidth
                  helperText={touched.email ? errors.email : ''}
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  placeholder="E-mail"
                  required
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nome Completo"
                  name="nomeCompleto"
                  onChange={handleChange}
                  placeholder="Nome Completo"
                  required
                  type="text"
                  value={values.nomeCompleto}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ padding: '1rem 0 0 0' }}>
              <Grid item sm={4} xs={12}>
                <TextFieldMask
                  error={Boolean(errors.cpf)}
                  fullWidth
                  handleChange={handleChange}
                  helperText={touched.cpf ? errors.cpf : ''}
                  label="CPF"
                  mask="999.999.999-99"
                  name="cpf"
                  placeholder="CPF"
                  required
                  type="text"
                  value={values.cpf}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextFieldMask
                  fullWidth
                  handleChange={handleChange}
                  label="Data de Nascimento"
                  mask="99/99/9999"
                  name="dataNascimento"
                  placeholder="Data de Nascimento"
                  type="text"
                  value={values.dataNascimento}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select-gender">Gênero * </InputLabel>
                  <Select
                    id="select-gender"
                    label="Gênero"
                    labelId="select-gender"
                    name="sexo"
                    onChange={handleChange}
                    required
                    value={values.sexo}
                  >
                    <MenuItem value="1">Masculino</MenuItem>
                    <MenuItem value="2">Feminino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ padding: '1rem 0 0 0' }}>
              <Grid item sm={6} xs={12}>
                <PasswordCustomField
                  handleChange={handleChange}
                  id="main-password-field"
                  name="senha"
                  value={values.senha}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <PasswordCustomField
                  error={Boolean(errors.senhaConfirmacao)}
                  handleChange={handleChange}
                  helperText={
                    touched.senhaConfirmacao ? errors.senhaConfirmacao : ''
                  }
                  id="secondary-password-field"
                  name="senhaConfirmacao"
                  value={values.senhaConfirmacao}
                />
              </Grid>
            </Grid>
            {/* Telefones */}
            <Grid container spacing={0}>
              <Grid
                container
                lg={4}
                sm={6}
                style={{ padding: '1rem 0.25rem 0 0' }}
                xs={12}
              >
                <Grid item sm={3} xs={3}>
                  <TextFieldMask
                    handleChange={handleChange}
                    label="DDD"
                    mask="99"
                    name="telefonePrincipalDDD"
                    placeholder="DDD"
                    required
                    style={{ marginRight: '0.5rem' }}
                    type="tel"
                    value={values.telefonePrincipalDDD}
                  />
                </Grid>
                <Grid item sm xs>
                  <TextFieldMask
                    fullWidth
                    handleChange={handleChange}
                    label="Telefone Principal"
                    mask="99999-9999"
                    name="telefonePrincipal"
                    placeholder="Telefone Principal"
                    required
                    type="tel"
                    value={values.telefonePrincipal}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                lg={4}
                sm={6}
                style={{ padding: '1rem 0 0 0.2rem' }}
                xs={12}
              >
                <Grid item sm={3} xs={3}>
                  <TextFieldMask
                    handleChange={handleChange}
                    label="DDD"
                    mask="99"
                    name="telefoneSecundarioDDD"
                    placeholder="DDD"
                    style={{ marginRight: '0.5rem' }}
                    type="tel"
                    value={values.telefoneSecundarioDDD}
                  />
                </Grid>
                <Grid item sm xs>
                  <TextFieldMask
                    fullWidth
                    handleChange={handleChange}
                    label="Telefone Secundario"
                    mask="99999-9999"
                    name="telefoneSecundario"
                    placeholder="Telefone Secundario"
                    type="tel"
                    value={values.telefoneSecundario}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ padding: '1rem 0' }} variant="h6">
                Endereço de Cadastro
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={2} sm={3}
xs={12}>
                <TextFieldMask
                  error={errorCep}
                  fullWidth
                  handleChange={handleChange}
                  helperText={errorCep && 'CEP não encontrado'}
                  label="CEP"
                  mask="99999-999"
                  name="cep"
                  onBlur={(e) => onBlurCep(e, setFieldValue)}
                  placeholder="CEP"
                  required
                  type="text"
                  value={values.cep}
                />
                <Grid item style={{ paddingLeft: 5 }} xs={12}>
                  <Typography style={{ display: 'contents' }} variant="caption">
                    <TypographyLink variant="inherit">
                      <a
                        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Não sabe o CEP ?
                      </a>
                    </TypographyLink>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={4} sm={5}
xs={12}>
                <TextField
                  fullWidth
                  label="Rua"
                  name="endereco"
                  onChange={handleChange}
                  placeholder="Rua"
                  required
                  type="text"
                  value={values.endereco}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} sm={4}
xs={12}>
                <TextField
                  fullWidth
                  label="Bairro"
                  name="bairro"
                  onChange={handleChange}
                  placeholder="Bairro"
                  required
                  type="text"
                  value={values.bairro}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} sm={3}
xs={12}>
                <TextFieldMask
                  fullWidth
                  handleChange={handleChange}
                  label="Número"
                  mask="999999"
                  name="numero"
                  placeholder="Número"
                  required
                  style={{ width: '100%' }}
                  type="text"
                  value={values.numero}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ padding: '1rem 0 0 0' }}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Cidade"
                  name="cidade"
                  onChange={handleChange}
                  placeholder="Cidade"
                  required
                  type="text"
                  value={values.cidade}
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Estado"
                  name="estado"
                  onChange={handleChange}
                  placeholder="Estado"
                  required
                  type="text"
                  value={values.estado}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ padding: '1rem 0 0 0' }}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Complemento"
                  name="complemento"
                  onChange={handleChange}
                  placeholder="Complemento"
                  type="tel"
                  value={values.complemento}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item style={{ padding: '1rem 0 0 0' }} xs>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.zonaRural}
                    color="primary"
                    name="zonaRural"
                    onChange={handleChange}
                  />
                }
                label="É Zona Rural"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.newsletter}
                    color="primary"
                    name="newsletter"
                    onChange={handleChange}
                  />
                }
                label="Receber Newsletter"
              />
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '1rem 0',
              }}
              xs={12}
            >
              <ButtonExtended
                color="secondary"
                loading={isSubmitting}
                style={{ color: 'white' }}
                type="submit"
                variant="contained"
              >
                Cadastrar
              </ButtonExtended>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CadastroFormulario;
