import { useCallback, useEffect, useState } from 'react';

import axios from 'axios';

import type { RecebimentoApi } from '.';
import { Carregando, Parcela } from '../../components';
import DrawerRecebimento from './drawer-recebimento';

const ParcelasPagas = () => {
  const [carregando, setCarregando] = useState(true);
  const [openDrawerRecebimento, setOpenDrawerRecebimento] = useState(false);
  const [parcelasPagas, setParcelasPagas] = useState<RecebimentoApi[]>([]);
  const [recebimentoSelecionado, setRecebimentoSelecionado] = useState({} as RecebimentoApi);

  const mostraRecebimento = useCallback((recebimento: RecebimentoApi) => {
    setRecebimentoSelecionado(recebimento);
    setOpenDrawerRecebimento(true);
  }, []);

  useEffect(() => {
    (async () => {

      const response = await axios.get('/v2/parcelas/pagas', { withCredentials: true });
      setParcelasPagas(response.data);

      setCarregando(false);
    })();
  }, []);

  if (carregando) {
    return (
      <Carregando />
    );
  }

  return (
    <>
      <div style={{ padding: '.5rem 0' }}>
        {
          parcelasPagas.map((p) => (
            <Parcela
              data={new Date(p.dataPagamento)}
              dataNome="Pago em:"
              key={p.identificador}
              onClick={() => mostraRecebimento(p)}
              tipo={p.tipo}
              valor={p.valorPago}
            />
          ))
        }
      </div>
      <DrawerRecebimento
        dataPagamento={recebimentoSelecionado.dataVencimento ? new Date(recebimentoSelecionado.dataPagamento) : undefined}
        onClose={() => setOpenDrawerRecebimento(false)}
        open={openDrawerRecebimento}
        parcelas={recebimentoSelecionado.parcelas}
        tipo={recebimentoSelecionado.tipo}
        valorPago={recebimentoSelecionado.valorPago}
      />
    </>
  );
};

export default ParcelasPagas;
