import React, { CSSProperties, FC } from 'react';
import InputMask from 'react-input-mask';

import { TextField } from '@material-ui/core';

interface TextFieldMaskProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  value: string | null | undefined;
  mask: string;
  label: string;
  type: string;
  required?: boolean;
  fullWidth?: boolean;
  placeholder: string;
  name: string;
  style?: CSSProperties | undefined;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  helperText?: React.ReactNode;
  error?: boolean | undefined;
  // id?: string;
}

const TextFieldMask: FC<TextFieldMaskProps> = ({
  handleChange,
  mask,
  label,
  name,
  type,
  required,
  placeholder,
  value,
  style,
  fullWidth,
  onBlur,
  helperText,
  error,
  // id,
}): JSX.Element => (
  <InputMask
    mask={mask}
    maskPlaceholder={null}
    name={name}
    onBlur={onBlur}
    onChange={handleChange}
    value={value as any}
  >
    <TextField
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      label={label}
      placeholder={placeholder}
      required={required}
      style={style}
      type={type}
      variant="outlined"
    />
  </InputMask>
);
TextFieldMask.defaultProps = {
  style: undefined,
  required: false,
  fullWidth: false,
  onBlur: () => null,
  error: false,
  helperText: undefined,
  // setTouched: () => null,
};

export default TextFieldMask;
