import { Dialog } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import { Instagram, YouTube } from "@material-ui/icons";
import styled from "styled-components";

import facebookIcon from '../assets/images/facebookIcon.png';
import theme from "../constants/theme";

const ModalAtendimentoStyle = styled.div`
  background-color: white;
  max-width: 20rem;
  height: 15rem;
  border-radius: 4px;
  padding: 0rem 2rem;
  margin: auto 0;

  p {
    text-align: center;
    font-size: 1rem;

    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: bold;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0;

    li {
      a {
        color: white;
        text-decoration: none;
        margin: 5px 0 0 0;
        background-color: ${theme.palette.primary.main};
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        img {
          object-fit: scale-down;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  div {
    text-align: center;
    font-size: 1rem;

    span {
      color: ${theme.palette.primary.main};
      font-weight: bold;
    }
  }
`;

interface IProps {
  toggleModalAtendimento: () => void;
  isModalAtendimentoOpen: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const ModalAtendimento = ({ toggleModalAtendimento, isModalAtendimentoOpen }: IProps) => (
  <Dialog
    aria-describedby="modal com contatos para atendimento ao cliente"
    aria-labelledby="Modal Atendimento"
    onClose={toggleModalAtendimento}
    open={isModalAtendimentoOpen}
  >
    <Box
      style={{
        // width: '19rem',
        // height: '19rem',
        // position: 'relative',
        paddingTop: '2rem',
        // top: '4rem',
        // margin: '-1 auto',
      }}
    >
      <ModalAtendimentoStyle>
        <p>
          Telefone: <a href="tel:8006513299">0800 651 3300</a>
        </p>
        <p>
          WhatsApp:{' '}
          <a href="https://api.whatsapp.com/send?phone=5565999684740">
            65 99968-4740
          </a>
        </p>
        <p>
          E-mail:{' '}
          <a href="mailto:sac@martinello.com.br">sac@martinello.com.br</a>
        </p>

        <ul>
          <li>
            <a
              href="https://www.facebook.com/eletromoveismartinello"
              rel="noreferrer"
              target="_blank"
            >
              <img alt="facebook icone" src={facebookIcon} />
            </a>
          </li>
          <li>
            <a
              aria-label="Instagram"
              href="https://www.instagram.com/eletromoveis.martinello"
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </a>
          </li>
          <li>
            <a
              aria-label="YouTube"
              href="http://www.youtube.com/c/Eletrom%C2%B3veisMartinello"
              rel="noreferrer"
              target="_blank"
            >
              <YouTube />
            </a>
          </li>
        </ul>

        <div>
          Horário de atendimento: <br />
          <span>
            {' '}
            Segunda a Sexta, das <br /> 7:00 às 17:30{' '}
          </span>
        </div>
      </ModalAtendimentoStyle>
    </Box>
  </Dialog>
);
