/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';

import { excluirMensagem, Mensagem } from '../../../api/lgpd/Lgpd';
import CustomModal from '../../../components/custom-modal';
import InsertHtmlDiv from '../../../components/insertHtmlDiv';
import { formatarDataeHora } from '../../../helpers/utils';

interface Props {
  mensagem: Mensagem;
  setEditMensagem: (mensagem: Mensagem) => void;
  getMensagens: () => Promise<void>;
  status: string;
}
export default function TemplateMensagem({
  mensagem,
  setEditMensagem,
  getMensagens,
  status,
}: Props) {
  const [openOpt, setOpenOpt] = useState(false);
  const [openCnfirmExcluir, setOpenCnfirmExcluir] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function handleExluirMensagem() {
    try {
      await excluirMensagem(mensagem.id);
      // await getMensagens
      await getMensagens();
      enqueueSnackbar('Mensagem excluída', { variant: 'success' });
      setOpenCnfirmExcluir(false);
    } catch (error) {
      enqueueSnackbar('Ocorreu algum erro ao enviar a mensagem', {
        variant: 'error',
      });
    }
  }

  if (mensagem.from === 'CLIENT') {
    return (
      <div className="w-full flex justify-end  my-6 rounded-lg">
        <div
          className={`relative text-left ${
            status === 'AGUARDANDO RESPOSTA' ? 'inline-block' : 'hidden'
          }  `}
        >
          {/* <button
            className="h-2 font-bold"
            onClick={() => {
              setOpenOpt(!openOpt);
            }}
            type="button"
          >
            <MoreVertIcon />
          </button> */}
          <div
            aria-labelledby="options-menu"
            aria-orientation="vertical"
            className={`absolute z-40 right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${
              openOpt ? 'block' : 'hidden'
            }`}
            role="menu"
          >
            <div className="py-1" role="none">
              <div
                className=" px-3 w-20 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                onClick={() => {
                  setOpenOpt(false);
                  setEditMensagem(mensagem);
                }}
                role="menuitem"
                // onKeyPress={() => findUrlAnexos}
              >
                Editar
              </div>
              {/* <div
                className=" px-3  w-20 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                onClick={() => {
                  setOpenOpt(false);
                  setOpenCnfirmExcluir(true);
                }}
                role="menuitem"
              >
                Excluir
              </div> */}
            </div>
          </div>
        </div>
        <div className="p-2 bg-red-50 max-w-[80%] rounded-lg break-words ">
          <InsertHtmlDiv response={mensagem.text} />
          <div className="w-full mt-2 flex gap-2">
            <p className="text-right text-sm text-slate-500">
              {formatarDataeHora(mensagem.createdAt)}
            </p>
          </div>
        </div>
        {/* <DialogConfirmacao
          cancelar
          confirmar={async () => {
            handleExluirMensagem();
          }}
          isOpen={openCnfirmExcluir}
          mensagem={' '}
          setIsOpen={setOpenCnfirmExcluir}
          titulo="Deseja excluir essa mensagem?"
        /> */}
        <CustomModal
          className="w-96"
          closeButton={false}
          footer={
            <div className="flex gap-4 w-full items-center justify-end flex-wrap mt-10">
              <button
                className={`flex rounded-md border-2 p-1 text-black
					hover:bg-gray-50 text-center justify-center items-center text-base px-2  `}
                onClick={() => setOpenCnfirmExcluir(false)}
                type="button"
              >
                Cancelar
              </button>
              <button
                className={`flex rounded-md border-2 bg-[#B80008] p-1 text-white
					hover:bg-red-800 text-center justify-center items-center text-base  px-2 `}
                onClick={() => handleExluirMensagem()}
                type="button"
              >
                Confirmar
              </button>
            </div>
          }
          isOpen={openCnfirmExcluir}
          setIsOpen={setOpenCnfirmExcluir}
          titulo="Deseja excluir a mensagem?"
        />
      </div>
    );
  }

  if (mensagem.from === 'DPO') {
    return (
      <div className="w-full flex justify-start my-6 rounded-lg">
        <div className="p-2 bg-cyan-50 max-w-[80%] rounded-lg break-words">
          <InsertHtmlDiv response={mensagem.text} />
          <div className="w-full mt-2">
            <p className="text-right text-sm text-slate-500">
              {formatarDataeHora(mensagem.createdAt)}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return <div> <p> </p></div>;
}
