export function toReal(valor: number): string {
  return Number(valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function copy(text: string): void {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
}

export function parseDateWithoutTz(value: string): Date {
  const date = value.substring(0, 10).split('-');
  let hour = '00:00:00';
  if (value.length > 10) {
    hour = value.substring(11, 19);
  }
  const formatedDate = new Date(`${date[0]}-${date[1]}-${date[2]}T${hour}`);

  return formatedDate;
}

export function parseDateWithoutTzToString(value: string) {
  const date = value.substring(0, 10).split('-');
  let hour = '00:00:00';
  if (value.length > 10) {
    hour = value.substring(11, 19);
  }
  const formatedDate = `${date[0]}-${date[1]}-${date[2]}`;

  return formatedDate;
}

export function getdata(data: Date): Date {
  const datas = new Date(data.getTime());
  return datas;
}

export const isMobile = () => {
  if (window.innerWidth <= 800 && window.innerHeight <= 900) {
    return true;
  }
  return false;
};

export function formatarData(dataStr: string): string {
  const data = new Date(dataStr);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do 0 em JavaScript, então adicionamos 1
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export function formatarDataeHora(data: string): string {
  if (data !== null) {
    const dateObj = new Date(data);

    const dia = dateObj.getDate();

    const mes = dateObj.getMonth() + 1;

    const ano = dateObj.getFullYear();

    const hora = dateObj.getHours();

    const minutos = dateObj.getMinutes();

    return `${dia.toString().padStart(2, '0')}/${mes
      .toString()
      .padStart(2, '0')}/${ano} - ${hora.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')}`;
  }
  return '';
}
