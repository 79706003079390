import { FC } from 'react';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface ButtonExtendedProps extends ButtonProps {
  loading?: boolean;
}

const ButtonExtended: FC<ButtonExtendedProps> = ({
  children,
  disabled,
  loading,
  ...restProps
}) => (
  <Button {...restProps} disabled={loading || disabled}>
    {children}
    {loading && <CircularProgress size={20} style={{ marginLeft: 8 }} />}
  </Button>
);

ButtonExtended.defaultProps = {
  loading: false
}

export default ButtonExtended;
