import React, { FC, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

interface PasswordCustomFieldProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  value: string | undefined;
  name: string;
  id: string;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}

const PasswordCustomField: FC<PasswordCustomFieldProps> = ({
  handleChange,
  value,
  name,
  id,
  label,
  error,
  helperText,
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <FormControl error={error} fullWidth variant="outlined">
      <InputLabel
        htmlFor={id}
        style={{ backgroundColor: '#ffff', padding: '0 5px' }}
      >
        {label}
      </InputLabel>

      <OutlinedInput
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              {!showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        fullWidth
        id={id}
        name={name}
        onChange={handleChange}
        required
        startAdornment={<LockOutlined htmlColor="gray" style={{margin: ' 0 10px 0 0'}}/>}
        type={showPassword ? 'text' : 'password'}
        value={value}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

PasswordCustomField.defaultProps = {
  label: 'Informe a senha * ',
  error: false,
  helperText: '',
};
export default PasswordCustomField;
