import { useEffect, useState } from 'react';

import { Link, Typography, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { DoubleArrow } from '@material-ui/icons';
import { ChevronDownIcon, Link2Icon } from '@radix-ui/react-icons';

import { Carregando } from '../../components';
import TitleV2 from '../../components/titleV2';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const seguros = [
  {
    titulo: 'Parcela Protegida Martinello',
    itens: [
      {
        subTitulo: 'Plano de Seguro I - CDC VINCULO EMPREGATÍCIO',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/bilhetes-seguros/Bilhete_Informacoes_PlanoI.pdf',
      },
      {
        subTitulo: 'Plano de Seguro II - CDC AUTÔNOMOS REGULAMENTADOS',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/bilhetes-seguros/Bilhete_Informacoes_PlanoII.pdf',
      },
      {
        subTitulo: 'Plano de Seguro III - CDC PROFISSIONAIS NÃO REGULAMENTADOS',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/bilhetes-seguros/Bilhete_Informacoes_PlanoIII.pdf',
      },
    ],
  },
  {
    titulo: 'Proteja Mais Martinello',
    itens: [
      {
        subTitulo: 'Garantia Estendida',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/bilhete-proteja-mais-martinello/Garantia_Estendida.pdf',
      },
    ],
  },
  {
    titulo: 'Proteção Quebra + RF',
    itens: [
      {
        subTitulo: 'Combo Roubo e Furto e Danos Materiais',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/protrecao-quebra-rf/Seguro_Combo_Roubo_Furto_Danos_Materiais.pdf',
      },
      {
        subTitulo: 'Danos Materiais Acidentais Plano I',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/protrecao-quebra-rf/Seguro_Danos_Materiais_Acidentais_Plano_I.pdf',
      },
      {
        subTitulo: 'Roubo e Furto Qualificado de Bens',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/protrecao-quebra-rf/Seguro_Roubo_Furto_Qualificado_Bens.pdf',
      },
    ],
  },
  {
    titulo: 'Troco Premiado',
    itens: [
      {
        subTitulo: 'Riscos Pessoais - DIH',
        link: 'https://arquivos-compartilhados-martinello.s3.sa-east-1.amazonaws.com/bilhete-riscos-pessoais/bilhete_capitalizacao_info_contrato.pdf',
      },

    ],
  },
];

const Seguros = (): JSX.Element => {
  const [carregando, setCarregando] = useState(true);
  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggle = (i: any) => {
    if (selected === i) {
      return setSelected(null);
    }
    return setSelected(i);
  };

  useEffect(() => {
    setCarregando(false);
  }, []);

  if (carregando) {
    return (
      <div className="flex p-11 justify-center items-center w-full mx-auto flew">
        <Carregando />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full min-h-[200px] pt-0 ">
      {/* <div className="flex flex-col pb-0">
        <Typography
          className="flex justify-between, items-center w-full pt-4 pb-2 bg-w"
          variant="h5"
        >
          <TitleV2 text="Seguros/Garantias" />
        </Typography>
      </div> */}
      <div className="flex flex-col p-4  items-center w-full ">
        {seguros.map((e, index) => (
          <Accordion
            className="flex flex-col lg:w-[775px] justify-center w-full hover:bg-slate-100"
            onChange={handleChange('panel1')}
            onClick={toggle}
            square
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              expandIcon={<ChevronDownIcon />}
              id="panel1d-header"
            >
              <div className="flex items-center">
                <span className="flex items-center gap-3 font-bold">
                  <DoubleArrow color="primary" />
                  {e.titulo}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col gap-2">
                {e.itens.map((item) => (
                  <div className="flex flex-col hover:bg-red-200 p-1 w-full rounded">
                    <Link
                      className="flex items-center gap-2"
                      href={item.link}
                      target="_blank"
                    >
                      <Link2Icon />
                      {item.subTitulo}
                    </Link>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
export default Seguros;
