/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import './Accordion.css';
import { CircularProgress, Divider } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import { Item } from '../api/servicos';
import iconProduct from '../assets/icones/produtos.png';
import iconProteja from '../assets/icones/protejaMaisMartinello.png';
import { toReal } from '../helpers/utils';
import Flex from './flex';

interface Props {
  itens: Item[] | any;
  load: boolean;
  erro: boolean;
}

const AccordionProduto = ({ itens, load, erro }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="accordion-container bg-white  ">
      <div
        className="accordion-header items-center justify-between "
        onClick={toggleAccordion}
      >
        <div className="flex gap-2 items-center">
          <img alt="icon" className="flex h-6" src={iconProduct} />
          <span className="text-black font-bold text-lg">Produtos</span>
        </div>
        <div>
          <span>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
        </div>
      </div>
      {load && (
        <Flex
          alignItems="center"
          direction="column"
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <CircularProgress />
        </Flex>
      )}
      {erro && (
        <Flex
          alignItems="center"
          direction="column"
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <div>
            <span className="flex gap-2 font-bold items-center">
              Erro ao carregar produto <ErrorOutlineOutlinedIcon  className='text-red-600'/>
            </span>
          </div>
        </Flex>
      )}
      {isOpen && (
        <div>
          {itens?.map((e: any, i: number) => (
            <div className="accordion-body max-w-full" key={i}>
              <p> Código: {e.produto.codigo}</p>
              <p className="font-bold flex-wrap">{e.produto.descricao}</p>

              <div className="flex justify-between pt-1">
                <p>Quantidade: {e.quantidade}</p>
                <p>Valor: {toReal(e.valorAVista)}</p>
              </div>

              {e.servicos.map((u: any) => (
                <div
                  className="flex gap-6 pt-2 items-center"
                  key={u.idVendaServico}
                >
                  <div className="flex">
                    <img alt="" className="h-16  w-16" src={iconProteja} />
                  </div>
                  <div className="flex flex-col w-full">
                    {u.observacao}
                    <div className="flex justify-between">
                      <span>{u.tempoCobertura} Meses</span>
                      <span>Valor: {toReal(u.valorTotalAPrazo)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          {itens?.length > 1 && <Divider style={{ margin: '.5rem 0' }} />}
        </div>
      )}
    </div>
  );
};

export default AccordionProduto;
