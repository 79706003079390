/* eslint-disable jsx-a11y/anchor-is-valid */

import { useCallback } from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import Cifrao from '@mui/icons-material/CurrencyExchangeOutlined';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';

import { ReactComponent as BoletoSvg } from '../assets/svgs/boleto.svg';
import { ReactComponent as CartaoSvg } from '../assets/svgs/cartao.svg';
import { ReactComponent as LojaSvg } from '../assets/svgs/loja.svg';
import { ReactComponent as PixSvg } from '../assets/svgs/pix.svg';
import { toReal } from '../helpers/utils';

const Badge = styled.div`
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  padding: 5px;

  @media (max-width: 380px) {
    max-width: 94px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 2rem;
  }

  ${(props) =>
    !props.checked &&
    css`
      svg {
        fill: #484747;
      }
    `}
`;

const StyledPaper = styled(Paper)`
  color: #6d6c6c;
  margin-bottom: 10px;
  padding: 0.5rem;
  width: 100%;
`;

export function getPagamentoIcone(tipo: string): JSX.Element | null {
  switch (tipo) {
    case 'Boleto':
      return <BoletoSvg />;

    case 'Cartão':
      return <CartaoSvg />;

    case 'Loja':
      return <LojaSvg />;

    case 'PIX':
      return <PixSvg />;

    default:
      return null;
  }
}

interface ParcelaProps {
  idVenda?: string;
  numeroParcela?: number;
  status?: string;
  valor: number;
  valorOriginal?: number;
  data: Date;
  dataNome?: string;
  onClick?: () => void;
  onSelect?: (selecionada: boolean) => void;
  selecionada?: boolean;
  tipo?: string;
  situacao?: string;
  onClickRenegociar?: () => void;
}

const Parcela = ({
  data,
  dataNome,
  idVenda,
  numeroParcela,
  status,
  valor,
  valorOriginal,
  onClick,
  onSelect,
  onClickRenegociar,
  selecionada,
  tipo,
  situacao,
}: ParcelaProps): JSX.Element => {
  const getIcon = useCallback(() => {
    switch (tipo) {
      case 'Boleto':
        return <BoletoSvg />;

      case 'Cartão':
        return <CartaoSvg />;

      case 'Loja':
        return <LojaSvg />;

      case 'PIX':
        return <PixSvg />;

      default:
        return null;
    }
  }, [tipo]);

  let cor = '#e4ebf1';
  let id = '';

  if (status?.toLowerCase().includes('aguardando pagamento')) {
    cor = '#fab913';
    id = 'aguardando';
  } else if (status?.toLowerCase().includes('atraso')) {
    id = 'atrasado';
    cor = '#e33737';
  } else if (
    status?.toLowerCase().includes('em dia') ||
    status?.toLowerCase().includes('pago')
  ) {
    cor = '#4cb050';
    id = 'em-dia';
  } else if (status?.toLowerCase().includes('vencer')) {
    cor = '#ff8e26';
    id = 'vencer';
  }

  return (
    <StyledPaper id={id} style={{ borderLeft: `5px solid ${cor}` }}>
      <Grid alignItems="center" container spacing={1}>
        <Grid item xs>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tipo && (
              <Grid alignItems="center" container spacing={1}>
                <Grid item>{getIcon()}</Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 21,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    {tipo}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!tipo && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={selecionada}
                      color="secondary"
                      onChange={(_, checked) =>
                        onSelect ? onSelect(checked) : undefined
                      }
                    />
                  }
                  label={`${selecionada ? 'Selecionada' : 'Selecione'} ${
                    situacao === 'R' ? 'para renegociar' : 'para pagamento'
                  }`}
                />
              </FormGroup>
            )}
            <Grid container style={{ marginTop: 5, alignItems: 'baseline' }}>
              <Grid item sm style={{ marginRight: 8 }}
xs={12}>
                <Typography component="span" variant="body2">
                  {dataNome}&nbsp;
                </Typography>
                <Typography
                  component="span"
                  style={{ fontWeight: 'bold' }}
                  variant="body1"
                >
                  {format(data, 'dd/MM/yyyy')}
                </Typography>
              </Grid>
              {numeroParcela && (
                <Grid item sm style={{ marginRight: 8 }}
xs={12}>
                  <Typography component="span" variant="body2">
                    Parcela:&nbsp;
                  </Typography>
                  <Typography component="span" variant="subtitle2">
                    {numeroParcela}
                  </Typography>
                </Grid>
              )}
              {idVenda && (
                <Grid item sm xs={12}>
                  <Typography component="span" variant="body2">
                    Venda:&nbsp;
                  </Typography>
                  <Typography component="span" variant="subtitle2">
                    {idVenda}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              alignItems: 'end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            {status && <Badge style={{ backgroundColor: cor }}>{status}</Badge>}
            {valorOriginal && valor !== valorOriginal ? (
              <span
                style={{
                  textDecorationLine: 'line-through',
                  marginTop: '0.5rem',
                }}
              >
                {toReal(valorOriginal)}
              </span>
            ) : null}

            <span style={{ color: '#484747', fontSize: 21, fontWeight: 700 }}>
              {toReal(valor)}
            </span>

            {tipo !== 'Loja' &&  situacao !== 'R' &&(
              <Link
                onClick={onClick}
                style={{
                  color: '#2295f2',
                  cursor: 'pointer',
                  fontSize: '1rem',
                }}
              >
                Detalhes
              </Link>
            )}
          </div>
        </Grid>
      </Grid>
      {/* {situacao === 'P' && (
        <button
          className="flex items-center gap-2 m-auto text-[#bf0926] scale-90"
          onClick={onClickRenegociar}
          type="button"
        >
            <Cifrao className="scale-125 " />
            <span className="text-xl hover:underline">Renegociar Contrato</span>
        </button>
      )} */}
    </StyledPaper>
  );
};

Parcela.defaultProps = {
  dataNome: 'Vencimento:',
  idVenda: undefined,
  numeroParcela: undefined,
  onClick: undefined,
  onSelect: undefined,
  selecionada: false,
  status: undefined,
  tipo: undefined,
  valorOriginal: undefined,
  situacao: undefined,
  onClickRenegociar: undefined,
};

export default Parcela;
