import { useCallback, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
} from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ShieldIcon from '@mui/icons-material/Shield';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import imagem from '../../assets/images/imagem.png';
import { Container } from '../../components';
import TitleV2 from '../../components/titleV2';
import Lgpd from '../lgpd/lgpd';
import SolicitacaoDetalhe from '../lgpd/lgpd-solicitacao-detalhes';
import LgpdInicial from '../lgpd/lgpd-tela-inicial';
import Privacidade from '../Privacidade/privacidade.index';
import Seguros from '../seguro/seguros';
import AlterarCadastro from './alterar-cadastro';

const TypographyLink = styled(Typography)`
  cursor: pointer;
  display: flex;
`;

const DadosCliente = () => {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const [selected, setSelected] = useState(null);

  const rota = window.location.pathname;

  const [selecionado, setSelecionado] = useState(rota);

  const history = useNavigate();

  const handleSpand = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggle = (i: any) => {
    if (selected === i) {
      return setSelected(null);
    }
    return setSelected(i);
  };

  const { id } = useParams<{ id: any }>();
  const { view } = useParams<{ view: any }>();
  const location = useLocation();

  const setPrivacidade = useCallback(() => {
    history('/dados-cliente/privacidade');
    setSelecionado('/dados-cliente/privacidade');
  }, [history]);

  const setSeguros = useCallback(() => {
    history('/dados-cliente/seguros');
    setSelecionado('/dados-cliente/seguros');
  }, [history]);

  const setDados = useCallback(() => {
    history('/dados-cliente/alterar-cadastro');
    setSelecionado('/dados-cliente/alterar-cadastro');
  }, [history]);

  const setVoltarDetahles = useCallback(() => {
    history('/dados-cliente/lgpd');
    setSelecionado('/dados-cliente/lgpd');
  }, [history]);

  return (
    <Container className="shadow-lg flex-col">
      <div className="flex gap-4 items-center pt-2">
        {location.pathname === '/dados-cliente/seguros' && (
          <Typography style={{ padding: '1rem 1rem 0 0' }} variant="h5">
            <TitleV2 enableGoBack text=" Seguros/Garantias" />
          </Typography>
        )}
        {location.pathname === '/dados-cliente/privacidade' && (
          <Typography style={{ padding: '1rem 1rem 0 0' }} variant="h5">
            <TitleV2 enableGoBack text=" Privacidade - LGPD" />
          </Typography>
        )}
        {location.pathname === '/dados-cliente/alterar-cadastro' && (
          <Typography style={{ padding: '1rem 1rem 0 0' }} variant="h5">
            <TitleV2 enableGoBack text=" Dados Pessoais" />
          </Typography>
        )}
        {location.pathname ===
          `/dados-cliente/lgpd-solicitacao-detalhes/${id}/false` && (
          <Typography style={{ padding: '1rem 1rem 0 0' }} variant="h5">
            <TitleV2 enableGoBack text=" Privacidade - LGPD" />
          </Typography>
        )}
        {location.pathname ===
          `/dados-cliente/lgpd-solicitacao-detalhes/${id}/true` && (
          <Typography style={{ padding: '1rem 1rem 0 0' }} variant="h5">
            {/* <TitleV2 enableGoBack text=" Privacidade - LGPD" /> */}
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <IconButton
                onClick={setVoltarDetahles}
                style={{ height: 'fit-content' }}
              >
                <ArrowBack color="primary" />
              </IconButton>

              <h3 style={{ paddingLeft: '1rem' }}>
                <strong style={{ fontSize: `20px` }}>Privacidade - LGPD</strong>
              </h3>
            </div>
          </Typography>
        )}
      </div>
      <div className="flex ">
        <div className="flex flex-col w-full lg:flex-row sm:w-full mb-6 gap-4 ">
          <div className="flex mt-4 mb-0 max-w-full lg:w-[268px] justify-center max-h-full border-none">
            <Accordion
              className="flex w-full lg:w-[268px] lg:h-0 flex-col rounded border-none"
              expanded={expanded === 'panel1'}
              onChange={handleSpand('panel1')}
              onClick={toggle}
              square
            >
              <div className="flex bg-[#d0252a] rounded flex-col text-white">
                <AccordionSummary
                  aria-controls="panel1d-content"
                  className=" flex justify-between"
                  expandIcon={<ChevronDownIcon />}
                  id="panel1d-header"
                >
                  <div className="flex items-center, justify-between w-full">
                    <span className="text-xl font-bold">Minha Conta</span>
                  </div>
                </AccordionSummary>
              </div>

              <AccordionDetails
                className={`focus:outline-none focus:ring-0 cursor-pointer active:shadow-lg transition duration-150 ease-in-out hover:bg-gray-100 rounded items-center
              ${
                selecionado === '/dados-cliente/alterar-cadastro' &&
                'text-red-500'
              }`}
                onClick={setDados}
              >
                <button
                  className="flex flex-row items-center gap-2 w-full"
                  onClick={setDados}
                  type="button"
                >
                  <AssignmentIndIcon />
                  <TypographyLink
                    onChange={() => setSelecionado('Dados')}
                    onClick={setDados}
                    style={{ display: 'contents' }}
                    variant="body2"
                  >
                    Alterar Cadastro
                  </TypographyLink>
                </button>

                {/* <div className="flex flex-col w-full">
                  <TypographyLink
                    onChange={() => setSelecionado('Dados')}
                    onClick={setDados}
                    style={{ display: 'contents' }}
                    variant="body2"
                  >
                    Alterar Cadastro
                  </TypographyLink>
                </div> */}
              </AccordionDetails>
              <AccordionDetails
                className={`focus:outline-none focus:ring-0 active:shadow-lg cursor-pointer transition duration-150 ease-in-out hover:bg-gray-100 rounded items-center
              ${selecionado === '/dados-cliente/seguros' && 'text-red-500'}`}
                onClick={setSeguros}
              >
                <button
                  className="flex flex-row items-center gap-2 w-full"
                  onClick={setSeguros}
                  type="button"
                >
                  <ShieldIcon />
                  <TypographyLink
                    onClick={setSeguros}
                    style={{ display: 'contents' }}
                    variant="body2"
                  >
                    Seguros/Garantias
                  </TypographyLink>
                </button>
                {/* <div className="flex flex-col w-full">
                  <TypographyLink
                    onClick={setSeguros}
                    style={{ display: 'contents' }}
                    variant="body2"
                  >
                    Seguros
                  </TypographyLink>
                </div> */}
              </AccordionDetails>
              <AccordionDetails
                className={`focus:outline-none focus:ring-0 active:shadow-lg cursor-pointer transition duration-150 ease-in-out hover:bg-gray-100 rounded items-center
              ${
                selecionado === '/dados-cliente/privacidade' && 'text-red-500'
              }`}
                onClick={setPrivacidade}
              >
                <button
                  className="flex flex-row items-center gap-2 w-full"
                  onClick={setPrivacidade}
                  type="button"
                >
                  <VisibilityOffOutlinedIcon />
                  <TypographyLink
                    onClick={setPrivacidade}
                    style={{ display: 'contents' }}
                    variant="body2"
                  >
                    Privacidade
                  </TypographyLink>
                </button>
              </AccordionDetails>
            </Accordion>
          </div>

          {selecionado === '/dados-cliente' && (
            <div className="flex justify-center items-center lg:pl-56">
              <img alt="Imagem" className="flex h-72 opacity-60" src={imagem} />
            </div>
          )}

          {selecionado === '/dados-cliente/alterar-cadastro' && (
            <div>
              <AlterarCadastro />
            </div>
          )}
          {selecionado === '/dados-cliente/lgpd' && (
            <div>
              <LgpdInicial />
            </div>
          )}
          {selecionado ===
            `/dados-cliente/lgpd-solicitacao-detalhes/${id}/${view}` && (
            <div>
              <SolicitacaoDetalhe />
            </div>
          )}
          {selecionado === '/dados-cliente/lgpd-nova-solicitacao' && (
            <div>
              <Lgpd />
            </div>
          )}
          <div>{selecionado === '/dados-cliente/seguros' && <Seguros />}</div>
          <div>
            {selecionado === '/dados-cliente/privacidade' && <Privacidade />}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default DadosCliente;
