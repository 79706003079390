/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import axios from 'axios';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import { useSnackbar } from 'notistack';

import type { ParcelaApi, RecebimentoApi } from '.';
import imagemBoleto from '../../assets/images/atencao-boleto.png';
import imagemPIX from '../../assets/images/pix-atencao.png';
// import TinaCut from '../../assets/images/martinello_033.png';
import { ReactComponent as BoletoSvg } from '../../assets/svgs/boleto.svg';
import { ReactComponent as PixSvg } from '../../assets/svgs/pix.svg';
import {
  Boleto,
  Carregando,
  Flex,
  Overlay,
  Parcela,
  Pix,
} from '../../components';
// import UserContext from '../../contexts/user';
import { isMobile, toReal } from '../../helpers/utils';
import DrawerParcelaDetalhes from './drawer-parcela-detalhes';
import DrawerRecebimento from './drawer-recebimento';
import DrawerRenegociarContrato from './drawer-renegociar-contrato';
import { ParcelaSelecionada, ParcelaSelecionadaDesktop } from './styles';

function ordernaParcelasAbertas(parcelas: ParcelaApi[]) {
  return sortBy(parcelas, ['dataVencimento', 'numeroParcela']);
}

interface ParcelasPerdidas {
  codigo: number;
  message: string;
  parcelas: [
    {
      id: string;
      nomCli: string;
      cgcCpf: number;
      situacao: string;
      quantidade: number;
    }
  ];
}

const ParcelasAbertas = (): JSX.Element => {
  const [carregando, setCarregando] = useState(true);
  const [drawerParcela, setDrawerParcela] = useState(false);
  const [drawerRecebimento, setDrawerRecebimento] = useState(false);
  const [drawerRenegociamento, setDrawerRenegociamento] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [isBottonOfPage, setIsBottonOfPage] = useState(false);
  const [modalOpcoesPagamento, setModalOpcoesPagamento] = useState(false);
  const [modalVencimentoBoleto, setModalVencimentoBoleto] = useState(false);
  const [parcelasAbertas, setParcelasAbertas] = useState<ParcelaApi[]>([]);
  const [parcelasAguardando, setParcelasAguardando] = useState<any[]>([]);
  // const [parcelasPerdidas, setPArcelasPerdidas] = useState<ParcelasPerdidas>();
  const [parcela, setParcela] = useState({} as ParcelaApi);
  const [parcelasSelecionadas, setParcelasSelecionadas] = useState<
    ParcelaApi[]
  >([]);
  const [processando, setProcessando] = useState(false);
  const [recebimentoSelecionado, setRecebimentoSelecionado] = useState(
    {} as RecebimentoApi
  );
  const [
    vencimentoIgualVencimentoParcela,
    setVencimentoIgualVencimentoParcela,
  ] = useState(false);

  const [boleto, setBoleto] = useState();
  const [pix, setPix] = useState();

  // const { usuario } = useContext(UserContext);

  const [isModalVisibility, setIsModalVisibility] = useState(false);
  const [isModalPixVisibility, setIsModalPixVisibility] = useState(false);

  const handleCloseModal = () => {
    setIsModalVisibility(false);
    setIsModalPixVisibility(false)
  };

  // const { handleGeraTokenParcela } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const buscaParcelasAbertas = useCallback(async () => {
    setCarregando(true);

    const response = await axios.get(`/v2/parcelas/abertas`, {
      withCredentials: true,
    });
    setParcelasAguardando(sortBy(response.data.aguardandoPagamento, ['id']));
    setParcelasAbertas(ordernaParcelasAbertas(response.data.abertas));
    setCarregando(false);
  }, []);

  const filter =
    parcelasAbertas.filter((e) => e.tipo === 'P' || e.tipo === 'O').length > 0;

  const calculaValorParcelasSelecionadas = useCallback(() => {
    const valorTotalSelecionadas = parcelasSelecionadas.map(
      (p) => p.valorTotal
    );

    return valorTotalSelecionadas.reduce((a, c) => a + c);
  }, [parcelasSelecionadas]);

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setIsBottonOfPage(true);
    } else {
      setIsBottonOfPage(false);
    }
  };

  const fechaModalVencimentoBoleto = useCallback(() => {
    setModalOpcoesPagamento(true);
    setModalVencimentoBoleto(false);
  }, []);

  const handleParcela = useCallback(
    (selecionada: boolean, parcelaAberta: ParcelaApi) => {
      if (selecionada) {
        setParcelasAbertas((prevParcelas) =>
          prevParcelas.filter(
            (p) => p.idParcelaErp !== parcelaAberta.idParcelaErp
          )
        );
        setParcelasSelecionadas((prevParcelas) =>
          ordernaParcelasAbertas([...prevParcelas, parcelaAberta])
        );
      } else {
        setParcelasSelecionadas((prevParcelas) =>
          prevParcelas.filter(
            (p) => p.idParcelaErp !== parcelaAberta.idParcelaErp
          )
        );
        setParcelasAbertas((prevParcelas) =>
          ordernaParcelasAbertas([...prevParcelas, parcelaAberta])
        );
      }
    },
    []
  );

  const mostraParcela = useCallback((parcelaAberta: ParcelaApi) => {
    setParcela(parcelaAberta);
    setDrawerParcela(true);
  }, []);

  const mostrarRenegociacao = useCallback((parcelaAberta: ParcelaApi) => {
    setParcela(parcelaAberta);
    setDrawerRenegociamento(true);
  }, []);

  const mostraRecebimento = useCallback((recebimento: RecebimentoApi) => {
    setRecebimentoSelecionado(recebimento);
    setDrawerRecebimento(true);
  }, []);

  const pagarComBoleto = useCallback(async () => {
    try {
      setProcessando(true);
      setModalOpcoesPagamento(false);
      setModalVencimentoBoleto(false);
      const response = await axios.post(
        'v2/parcelas/pagamento/boleto',
        {
          ids: parcelasSelecionadas.map((p) => p.idParcelaErp),
          vencimentoIgualVencimentoParcela,
        },
        {
          headers: {
            'X-App-Version': 'Web',
          },
          withCredentials: true,
        }
      );
      setBoleto(response.data);
      setProcessando(false);
    } catch (error) {
      setProcessando(false);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  }, [parcelasSelecionadas, vencimentoIgualVencimentoParcela, enqueueSnackbar]);

  const pagarComPix = useCallback(async () => {
    setIsModalPixVisibility(true);
    try {
      setProcessando(true);
      setModalOpcoesPagamento(false);
      const response = await axios.post(
        'v2/parcelas/pagamento/pix',
        {
          ids: parcelasSelecionadas.map((p) => p.idParcelaErp),
        },
        {
          headers: {
            'X-App-Version': 'Web',
          },
          withCredentials: true,
        }
      );
      setPix(response.data);
      setProcessando(false);
    } catch (error) {
      setProcessando(false);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  }, [parcelasSelecionadas, enqueueSnackbar]);

  const verificaBoleto = useCallback(() => {
    setIsModalVisibility(true);
    if (
      parcelasSelecionadas.length === 1 &&
      ['Em dia', 'A vencer'].includes(parcelasSelecionadas[0].status)
    ) {
      setModalVencimentoBoleto(true);
      setModalOpcoesPagamento(false);
    } else {
      pagarComBoleto();
    }
  }, [pagarComBoleto, parcelasSelecionadas]);

  useEffect(() => {
    // handleGeraTokenParcela()
    buscaParcelasAbertas();
  }, [buscaParcelasAbertas]);

  if (carregando) {
    return <Carregando />;
  }

  const parcelas = parcelasAbertas.filter((p) => p.status.includes(filtro));

  const valorParcelasSelecionadas = parcelasSelecionadas.reduce(
    (total, p) => total + p.valorTotal,
    0
  );
  const selecionarTodas = (selection: boolean) => {
    if (selection) {
      parcelas.map((e) => handleParcela(selection, e));
    } else {
      parcelasSelecionadas.map((e) => handleParcela(selection, e));
    }
  };

  return (
    <>
      {processando && (
        <Overlay>
          <Carregando mensagem="Aguarde..." style={{ margin: 0 }} />
        </Overlay>
      )}

      {(boleto || pix) && (
        <Overlay>
          {boleto && <Boleto boleto={boleto} />}
          {pix && <Pix pix={pix} />}
          <Button
            onClick={() => {
              setBoleto(undefined);
              setPix(undefined);
              setParcelasSelecionadas([]);
              buscaParcelasAbertas();
            }}
            startIcon={<ArrowBack style={{ fill: 'red' }} />}
            style={{ color: '#fff', marginTop: 10 }}
            variant="text"
          >
            Voltar
          </Button>
        </Overlay>
      )}
      {!isMobile() &&
        document.documentElement.scrollHeight &&
        document.body.offsetHeight > window.innerHeight &&
        parcelasSelecionadas.length > 0 &&
        !isBottonOfPage ? (
        <ParcelaSelecionadaDesktop href="#parcelaSelecionada">
          <span>
            Total das Parcelas {toReal(calculaValorParcelasSelecionadas())}
          </span>
        </ParcelaSelecionadaDesktop>
      ) : null}
      {isMobile() && parcelasSelecionadas.length > 0 && !isBottonOfPage ? (
        <ParcelaSelecionada href="#parcelaSelecionada">
          <span>
            Total das Parcelas {toReal(calculaValorParcelasSelecionadas())}
          </span>
        </ParcelaSelecionada>
      ) : null}
      <Grid
        container
        style={{ justifyContent: 'space-between', padding: '.5rem 0' }}
      >
        <Grid container spacing={1} style={{ width: '400px' }}>
          <Grid item>
            <Chip
              color={filtro === '' ? 'primary' : 'default'}
              label="Todas"
              onClick={() => setFiltro('')}
              style={{ backgroundColor: filtro === '' ? 'gray' : '' }}
            />
          </Grid>
          <Grid item>
            <Chip
              color={filtro === 'Em atraso' ? 'primary' : 'default'}
              label="Em atraso"
              onClick={() => setFiltro('Em atraso')}
            />
          </Grid>
          <Grid item>
            <Chip
              color={filtro === 'A vencer' ? 'primary' : 'default'}
              label="A vencer"
              onClick={() => setFiltro('A vencer')}
              style={{
                backgroundColor: filtro === 'A vencer' ? '#ff8e26' : '',
              }}
            />
          </Grid>
          <Grid item>
            <Chip
              color={filtro === 'Em dia' ? 'primary' : 'default'}
              label="Em dia"
              onClick={() => setFiltro('Em dia')}
              style={{ backgroundColor: filtro === 'Em dia' ? '#4cb050' : '' }}
            />
          </Grid>
          <Grid item style={{ alignSelf: 'flex-start', padding: '0 0 0 0' }}>
            {parcelasAbertas.length > 0 && (
              <button
                className="m-1 px-2 py-[2px] hover:opacity-80 transition-opacity
               duration-200 uppercase font-bold border-[2px] rounded-full border-gray-500
               text-gray-800 tracking-wide"
                onClick={() => selecionarTodas(true)}
                type="button"
              >
                Selecionar Todas
              </button>
            )}
            {parcelasSelecionadas.length > 0 && (
              <button
                className="m-1 px-2 py-[2px] hover:opacity-80 transition-opacity
               duration-200 uppercase font-bold border-[2px] rounded-full border-gray-500
               text-gray-800 text tracking-wide"
                onClick={() => selecionarTodas(false)}
                type="button"
              >
                Desmarcar Todas
              </button>
            )}
            {/* {isMobile() && filter && (
              <div className="flex h-36 border border-red-600 rounded-xl mb-2 mt-2 border-4">
                <img alt="a" src={TinaCut} />
                <div className="flex items-center">
                  <p className="lg:text-[16px]">
                    Olá {usuario?.nome} identificamos que existe algumas
                    parcelas vencidas a mais de 1 ano. Entre em contato com a
                    loja para saber mais
                  </p>
                </div>
              </div>
            )} */}
          </Grid>
        </Grid>

        <Grid item style={{ alignSelf: 'flex-end', padding: '0.5rem 0 0 0' }}>
          <Typography
            style={{ display: 'flex', flexDirection: 'row' }}
            variant="body2"
          >
            Formas de Pagamento:{' '}
            <span
              style={{
                fontWeight: 'bold',
                display: 'flex',
                gap: '0.3rem',
              }}
            >
              <span style={{ display: 'flex', gap: '4px' }}>
                {' '}
                Boleto
                <BoletoSvg style={{ width: '20px', height: '20px' }} />
              </span>
              ,{' '}
              <span style={{ display: 'flex', gap: '4px' }}>
                PIX
                <PixSvg style={{ width: '20px', height: '20px' }} />
              </span>
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          {parcelasAguardando.map((r) => (
            <Parcela
              data={new Date(r.dataVencimento)}
              key={r.id}
              onClick={() => mostraRecebimento(r)}
              status="Aguardando pagamento"
              tipo={r.tipo}
              valor={r.valorTotal}
            />
          ))}
          {parcelas.map((p) => (
            <Parcela
              data={new Date(p.dataVencimento)}
              idVenda={p.idVenda}
              key={p.idParcelaErp}
              numeroParcela={p.numeroParcela}
              onClick={() => mostraParcela(p)}
              onClickRenegociar={() => mostrarRenegociacao(p)}
              onSelect={(selecionada) => handleParcela(selecionada, p)}
              situacao={p.tipo}
              status={p.status}
              valor={p.valorTotal}
              valorOriginal={p.valorOriginal}
            />
          ))}
        </Grid>
        <Grid item md={6} xs={12}>
          {/* {!isMobile() && filter && (
            <div className="flex h-36 border border-red-600 rounded-xl mb-2 border-4">
              <img alt="a" src={TinaCut} />
              <div className="flex items-center">
                <p className="lg:text-[16px]">
                  Olá {usuario?.nome} identificamos que existe algumas parcelas
                  vencidas a mais de 1 ano. Entre em contato com a loja para
                  saber mais
                </p>
              </div>
            </div>
          )} */}
          <Card style={{ position: 'sticky', top: '7rem' }}>
            <CardContent>
              <Typography style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Pagamento de uma (ou mais) parcelas
              </Typography>

              {parcelasSelecionadas.map((p) => (
                <Parcela
                  data={new Date(p.dataVencimento)}
                  idVenda={p.idVenda}
                  key={p.idParcelaErp}
                  numeroParcela={p.numeroParcela}
                  onClick={() => mostraParcela(p)}
                  onClickRenegociar={() => mostrarRenegociacao(p)}
                  onSelect={(selecionada) => handleParcela(selecionada, p)}
                  selecionada
                  status={p.status}
                  valor={p.valorTotal}
                  valorOriginal={p.valorOriginal}
                />
              ))}

              <Divider style={{ margin: '1rem 0' }} />

              <Grid
                container
                id="parcelaSelecionada"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item>
                  <Typography>Quantidade de parcelas</Typography>
                </Grid>
                <Grid item>
                  <Typography>{parcelasSelecionadas.length}</Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                  <Typography>Total das parcelas</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {toReal(valorParcelasSelecionadas)}
                  </Typography>
                </Grid>
              </Grid>

              <Button
                color="secondary"
                disabled={valorParcelasSelecionadas === 0}
                fullWidth
                onClick={() => setModalOpcoesPagamento(true)}
                size="large"
                style={{ color: '#fff', fontWeight: 'bold', marginTop: '2rem' }}
                variant="contained"
              >
                Efetuar Pagamento
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {drawerParcela && (
        <DrawerParcelaDetalhes
          onClose={() => setDrawerParcela(false)}
          open
          parcela={parcela}
        />
      )}
      {drawerRenegociamento && (
        <DrawerRenegociarContrato
          onClose={() => setDrawerRenegociamento(false)}
          open
          parcela={parcela}
        />
      )}
      <DrawerRecebimento
        boleto={{
          ...recebimentoSelecionado.boleto,
          dataVencimento: recebimentoSelecionado.dataVencimento,
          valorTotal: recebimentoSelecionado.valorTotal,
        }}
        onClose={() => setDrawerRecebimento(false)}
        open={drawerRecebimento}
        parcelas={recebimentoSelecionado.parcelas}
        pix={recebimentoSelecionado.pix}
        tipo={recebimentoSelecionado.tipo}
        valorPago={recebimentoSelecionado.valorTotal}
      />
      <Dialog
        onClose={() => setModalOpcoesPagamento(false)}
        open={modalOpcoesPagamento}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Typography
            color="primary"
            style={{ fontWeight: 'bold', fontSize: 18 }}
          >
            Opções de pagamento
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '8px 0' }}>
          <List style={{ minWidth: 300 }}>
            <ListItem
              button
              divider
              onClick={verificaBoleto}
              style={{ paddingTop: 16 }}
            >
              <Flex
                alignItems="center"
                direction="column"
                style={{ width: '100%' }}
              >
                <BoletoSvg />
                <p style={{ marginBottom: 0 }}>Pagar com Boleto</p>
              </Flex>
            </ListItem>
            <ListItem
              button
              divider
              onClick={pagarComPix}
              style={{ paddingTop: 16 }}
            >
              <Flex
                alignItems="center"
                direction="column"
                style={{ width: '100%' }}
              >
                <PixSvg />
                <p style={{ marginBottom: 0 }}>Pagar com PIX</p>
              </Flex>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={() => setModalOpcoesPagamento(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={fechaModalVencimentoBoleto} open={modalVencimentoBoleto}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Typography
            color="primary"
            style={{ fontWeight: 'bold', fontSize: 18 }}
          >
            Boleto
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '8px 0', width: 350 }}>
          <div style={{ padding: '0 24px' }}>
            <Typography
              gutterBottom
              style={{ textAlign: 'justify' }}
              variant="body2"
            >
              Se preferir é possível alterar o vencimento do boleto para o mesmo
              vencimento da parcela:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(_, checked) =>
                    setVencimentoIgualVencimentoParcela(checked)
                  }
                />
              }
              label={`Vencimento para o dia ${parcelasSelecionadas.length > 0
                ? format(
                  new Date(parcelasSelecionadas[0]?.dataVencimento),
                  'dd/MM/yyyy'
                )
                : ''
                }`}
            />
            {vencimentoIgualVencimentoParcela && (
              <div>
                {' '}
                *Desconto não aplicável para pagamento futuros <br />{' '}
                <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  {' '}
                  Valor a ser pago{' '}
                  {toReal(parcelasSelecionadas[0]?.valorOriginal)}{' '}
                </span>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={fechaModalVencimentoBoleto}>
            Cancelar
          </Button>
          <Button autoFocus color="primary" onClick={pagarComBoleto}>
            Gerar Boleto
          </Button>
        </DialogActions>
      </Dialog>
      {isModalVisibility && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="text-red-500">
              Fique atento na hora de fazer seus pagamentos!!!
            </h2>
            <p className="font-bold text-lg">
              Para evitar fraudes, é fundamental que você sempre verifique os
              dados do beneficiário antes de realizar o pagamento do boleto. No
              app do banco, confira os dados do beneficiário abaixo:
            </p>
            <div className="flex flex-col ">
              <li>Nome do Beneficiário: Eletromóveis Martinello.</li>
              <li>CNPJ: 32.951.535/0001-34.</li>
            </div>
            <span className="flex pt-4 font-bold text-lg">
              Abaixo temos o exemplo de como deve ser o boleto.
            </span>
            <img
              alt="Imagem Boleto MArtinello"
              className="lg:h-60 lg:w-full"
              src={imagemBoleto}
            />
            <button onClick={handleCloseModal} type="button">
              Marcar como lido
            </button>
          </div>
        </div>
      )}
      {isModalPixVisibility && (
        <div className="modal">
          <div className="modal-content">
            <h2 className="text-red-500">
              Fique atento na hora de fazer seus pagamentos!!!
            </h2>
            <p className="font-bold text-lg">
              Para evitar fraudes, é fundamental que você sempre verifique os
              dados do beneficiário antes de realizar o pagamento do PIX. No
              APP do banco, confira os dados do beneficiário abaixo:
            </p>
            <div className="flex flex-col ">
              <li>Nome do Beneficiário: Eletromóveis Martinello.</li>
              <li>CNPJ: 32.951.535/0001-34.</li>
              <li>Instituição: Banco do Brasil S.A.</li>
            </div>
            <span className="flex pt-4 font-bold text-lg">
              Abaixo temos o exemplo do pagamento com PIX.
            </span>
            <img
              alt="Imagem Boleto MArtinello"
              className="mb-4"
              src={imagemPIX}
            />
            <button onClick={handleCloseModal} type="button">
              Marcar como lido
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ParcelasAbertas;
