/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-max-props-per-line */
import { GiClick } from 'react-icons/gi';

import { Grid, Link, Typography } from '@material-ui/core';
import {
  BusinessOutlined,
  Facebook,
  Instagram,
  LocationOnOutlined,
  YouTube,
} from '@material-ui/icons';

import {
  ListaContatos,
  ListaRedesSocial,
  RedeSocial,
  themeColors,
  Title,
  TitleContato,
} from './styles';

const Contato = (): JSX.Element => (
  <div style={{ marginBottom: 50 }}>
    <Title style={{ textAlign: 'center' }}>
      <span>Contato</span>
    </Title>
    <Grid container>
      <Grid item md={4} sm={4} xs={12}>
        <TitleContato>Atendimento</TitleContato>
        <ListaContatos style={{ listStyle: 'none' }}>
          <li>
            <span>Telefone: </span>
            <Link
              href="tel:08006513300"
              rel="noopener noreferrer"
              target="_blank"
            >
              0800 651 3300
            </Link>
          </li>
          <li>
            <span>WhatsApp: </span>
            <Link
              href="https://api.whatsapp.com/send?phone=5565999684740"
              rel="noopener noreferrer"
              target="_blank"
            >
              (65) 99968-4740
            </Link>
          </li>
          <li>
            <span>E-mail: </span>
            <Link href="mailto:sac@martinello.com.br">
              sac@martinello.com.br
            </Link>
          </li>
        </ListaContatos>
        <p className='flex lg:justify-start justify-center gap-1 lg:flex-col' style={{ color: themeColors.mainRed }}>
          Horário de atendimento:
          <br />
          <small className='flex items-center' style={{ color: 'gray' }}>
            Segunda a sexta, das 08:00 às 17:30
          </small>
        </p>
        <Grid className='flex lg:justify-start justify-center' item>
          <div className="flex pt-2">
            <ul>
              <a className="flex gap-2 text-[#f53d23] items-center" href="/canais-digitais">
                Canais Digitais - Martinello
                <div className=''>
                  <GiClick />
                </div>
              </a>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <TitleContato>Redes Sociais</TitleContato>
        <ListaRedesSocial style={{ listStyle: 'none' }}>
          <li>
            <RedeSocial
              href="https://www.facebook.com/eletromoveismartinello"
              target="_blank"
            >
              <Facebook fontSize="large" htmlColor="#4267b2" /> Facebook
            </RedeSocial>
          </li>
          <li>
            <RedeSocial
              href="https://www.instagram.com/eletromoveis.martinello"
              target="_blank"
            >
              <Instagram fontSize="large" htmlColor="#c13584" /> Instagram
            </RedeSocial>
          </li>
          <li>
            <RedeSocial
              href="https://www.youtube.com/c/Eletrom%C3%B3veisMartinello"
              target="_blank"
            >
              <YouTube fontSize="large" htmlColor="#ff0000" /> YouTube
            </RedeSocial>
          </li>
        </ListaRedesSocial>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <TitleContato>Empresa</TitleContato>
        <ListaContatos style={{ listStyle: 'none' }}>
          <li style={{ alignItems: 'center', display: 'flex' }}>
            <BusinessOutlined color="primary" fontSize="large" />
            <Typography gutterBottom variant="body2">
              Eletromar Moveis Eletrodomesticos LTDA
              <br />
              CNPJ: 32.951.535/0001-34
            </Typography>
          </li>
          <li style={{ alignItems: 'center', display: 'flex' }}>
            <LocationOnOutlined color="primary" fontSize="large" />
            <Typography variant="body2">
            Rodovia da Mudança, 6399N - Industrial
              <br />
              Lucas do Rio Verde/MT | CEP: 78460-417
            </Typography>
          </li>
        </ListaContatos>
      </Grid>
    </Grid>
  </div>
);

export default Contato;
