import { CSSProperties, FC } from 'react';

import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/naming-convention
type valueof<T> = T[keyof T];

type AlignItems = {
  baseline: 'baseline',
  center: 'center',
  end: 'end',
  flexEnd: 'flex-end',
  flexStart: 'flex-start',
  inherit: 'inherit',
  initial: 'initial',
  normal: 'normal',
  revert: 'revert',
  selfEnd: 'self-end',
  selfStart: 'self-start',
  start: 'start',
  stretch: 'stretch',
  unset: 'unset'
};

type Direction = {
  column: 'column',
  columnReverse: 'column-reverse',
  inherit: 'inherit',
  initial: 'initial',
  revert: 'revert',
  row: 'row',
  rowReverse: 'row-reverse',
  unset: 'unset'
};

type JustifyContent = {
  center: 'center',
  end: 'end',
  flexEnd: 'flex-end',
  flexStart: 'flex-start',
  inherit: 'inherit',
  initial: 'initial',
  left: 'left',
  normal: 'normal',
  revert: 'revert',
  right: 'right',
  spaceAround: 'space-around',
  spaceBetween: 'space-between',
  spaceEvenly: 'space-evenly',
  start: 'start',
  stretch: 'stretch',
  unset: 'unset'
};

interface FlexProps {
  alignItems?: valueof<AlignItems>;
  direction?: valueof<Direction>;
  justifyContent?: valueof<JustifyContent>;
  style?: CSSProperties;
}

const Div = styled.div<FlexProps>`
  align-items: ${(props) => props.alignItems};
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justifyContent};
`;

const Flex: FC<FlexProps> = ({ children, style, ...restProps }) => (
  <Div {...restProps} style={style}>
    {children}
  </Div>
);

Flex.defaultProps = {
  alignItems: 'normal',
  direction: 'row',
  justifyContent: 'flex-start',
  style: undefined
};

export default Flex;
