import { Fragment, useCallback, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';

import { CircularProgress, Drawer, Modal, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import axios, { AxiosResponse } from 'axios';
import styled from 'styled-components';

import type { ParcelaApi } from '.';
import { ButtonExtended, Flex, Parcela } from '../../components';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 370 !important;
  }

  @media (max-width: 420px) {
    .MuiDrawer-paper {
      width: 86% !important;
    }
  }
`;

interface DrawerParcelaDetalhesProps {
  onClose: () => void;
  open: boolean;
  parcela: ParcelaApi;
}

interface Cadastro {
  email: string;
  cpf: string;
  nomeCompleto: string;
  telefone: string;
  idVenda: string;
  lojaNome: string;
}

const DrawerRenegociarContrato = ({
  onClose,
  open,
  parcela,
}: DrawerParcelaDetalhesProps): JSX.Element => {
  const [carregando, setCarregando] = useState(false);
  const [user, setUser] = useState<Cadastro>({} as Cadastro);
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  const handleSubmit = useCallback(async () => {
    const ac = new AbortController();
    setCarregando(true);

    try {
      await axios.post(
        'https://2t67kowff6.execute-api.sa-east-1.amazonaws.com/dev/v2/parcelas/renegociacao',
        {
          nome: user.nomeCompleto,

          cpf: user.cpf.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})$/g,
            '$1.$2.$3-$4'
          ),
          email,
          telefone: telefone
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2 ')
            .replace(/(\d)(\d{4})$/, '$1-$2'),
          idVenda: parcela.idVenda,
          lojaNome: parcela.lojaNome,
        }
      );
      setCarregando(false);
      setOpenModal(true);
    } catch (error) {
      console.log(error);
    }
    ac.abort();
  }, [user, email, telefone, parcela]);

  useEffect(() => {
    (async () => {
      try {
        const responseCliente: AxiosResponse = await axios.get(
          '/v1/minha-conta'
        );
        setUser(responseCliente.data);
        setTelefone(responseCliente.data.telefonePrincipal);
        setEmail(responseCliente.data.email);
        console.log(responseCliente);

        setCarregando(false);
      } catch (error) {
        setCarregando(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleCelular = (event: any) => {
    setTelefone(event.target.value);
  };

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  if (openModal) {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        className="flex justify-center"
        onClose={handleClose}
        open={open}
      >
        <div className="flex flex-col gap-4 p-4 justify-center m-auto h-44 w-80  bg-white text-center rounded border border-red-600">
          <span className="text-red-500 text-xl ">
            Processo de renegociação iniciado ...
          </span>

          <p>
            Entraremos em contato em até 10 dias, para apresentação de
            negociação...
          </p>

          <button
            className="flex rounded bg-red-600 text-white hover: bg-red-700 w-10 p-1 justify-center items-center m-auto font-bold"
            onClick={handleClose}
            type="button"
          >
            OK
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <StyledDrawer anchor="right" onClose={onClose} open={open}>
      <div className="m-2 flex justify-between">
        <Typography className="pt-2" color="primary" gutterBottom
variant="h6">
          Negociação
        </Typography>
        <button
          aria-label="Fechar"
          onClick={onClose}
          style={{ border: 'none', background: 'none' }}
          type="button"
        >
          <CloseOutlined />
        </button>
      </div>

      <div className=" flex flex-col m-2 rounded border  border-red-600 p-2">
        <span className="text-lg">Informações da renegociação do contrato</span>
        <span>Confirme os dado de contato para prosseguir.</span>
        <span className="font-bold">Venda: {parcela.idVenda}</span>
        &nbsp;
        <div className="flex flex-col gap-2">
          <div className="flex  flex-col gap-4">
            <div>
              <span className="block mb-2 text-sm font-medium text-gray-900 ">
                Nome
              </span>
              <input
                className="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                disabled
                id="nomeCompleto"
                placeholder="Nome"
                required
                type="text"
                value={user.nomeCompleto}
              />
            </div>
            <div>
              <span className="block mb-2 text-sm font-medium text-gray-900 ">
                E-mail
              </span>
              <input
                className="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="email"
                onChange={(e) => handleEmail(e)}
                placeholder="E-mail"
                required
                type="text"
                value={email}
              />
            </div>
            <div>
              <span className="block mb-2 text-sm font-medium text-gray-900 ">
                Celular
              </span>
              <ReactInputMask
                className="  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                id="telefone"
                mask="(99) 99999-9999"
                onChange={(e) => handleCelular(e)}
                placeholder="Celular"
                required
                type="text"
                value={telefone}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex m-2">
        <Parcela
          data={new Date(parcela.dataVencimento)}
          idVenda={parcela.idVenda}
          key={parcela.idParcelaErp}
          numeroParcela={parcela.numeroParcela}
          selecionada
          situacao="R"
          status={parcela.status}
          valor={parcela.valorTotal}
          valorOriginal={parcela.valorOriginal}
        />
      </div>
      <Flex direction="column" style={{ padding: '0 1rem' }}>
        {/* {carregando && (
          <Flex
            alignItems="center"
            direction="column"
            style={{ marginTop: '1rem' }}
          >
            <CircularProgress />
          </Flex>
        )} */}

        <ButtonExtended
          color='primary'
          loading={carregando}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          Iniciar Renegociação
        </ButtonExtended>
      </Flex>
    </StyledDrawer>
  );
};

export default DrawerRenegociarContrato;
function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error('Function not implemented.');
}
