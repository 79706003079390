import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Carregando } from '../../components';
import UserContext from '../../contexts/user';

const Logout = (): JSX.Element => {
  const { clear } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(async () => {
      clear();
      await axios.get('/v1/auth/logout', { withCredentials: true });
      navigate('/');
    }, 1500);
  }, [clear, navigate]);

  return (
    <Carregando mensagem="Deslogando..." />
  );
};

export default Logout;
