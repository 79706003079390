import styled from 'styled-components';

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, .9);
  /* bottom: 0; */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

export default Overlay;
