import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
// import ReactQuill from 'react-quill';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
  editarMensagem,
  enviarMensagem,
  Mensagem,
} from '../../../api/lgpd/Lgpd';
// import { toast } from 'react-toastify';

interface Props {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  editMensagem: Mensagem | null;
  // getMensagens: Promise<void>;
}
export default function ModalMensagem({
  openModal,
  setOpenModal,
  editMensagem /* getMensagens */,
}: Props) {
  const { id } = useParams();
  const [text, setText] = useState<string>('');
  const [load, setLoad] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(editMensagem);
    if (editMensagem != null) {
      setText(editMensagem?.text || '');
    }
    if (openModal === false) {
      setText('');
    }
  }, [editMensagem, openModal]);

  async function handleSendMessage() {
    setLoad(true);
    const data = {
      text,
      requestId: id as string,
    };
    if (data.text === '' || data.text === '<p><br></p>') {
      enqueueSnackbar('Escreva uma mensagem para enviar', {
        variant: 'warning',
      });
      setLoad(false);
      return;
    }
    try {
      await enviarMensagem(data);
      // await getMensagens
      enqueueSnackbar('Mensagem enviada', { variant: 'success' });
      setOpenModal(false);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      enqueueSnackbar('Ocorreu algum erro ao enviar a mensagem', {
        variant: 'error',
      });
    }
    setLoad(false);
  }

  async function handleEditMessage() {
    setLoad(true);
    const data = {
      text,
      mensagemId: editMensagem?.id as string,
    };
    if (data.text === '' || data.text === '<p><br></p>') {
      enqueueSnackbar('Escreva uma mensagem para enviar', {
        variant: 'warning',
      });
      setLoad(false);
      return;
    }
    try {
      await editarMensagem(data);
      // await getMensagens
      enqueueSnackbar('Mensagem salva', { variant: 'success' });
      setOpenModal(false);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      enqueueSnackbar('Ocorreu algum erro ao salvar a mensagem', {
        variant: 'error',
      });
    }
    setLoad(false);
  }

  return (
    <div>
      {openModal && (
        <div
          aria-labelledby="modal-title"
          aria-modal="true"
          className="fixed z-10 inset-0 overflow-y-auto"
          role="dialog"
        >
          <div className="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              aria-hidden="true"
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
            >
              &#8203;
            </span>
            <div className="inline-block p-5 align-bottom max-h-[85vh] bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[57vw] sm:w-full">
              <div className="bg-white mb-3">
                {editMensagem != null ? (
                  <span className="font-bold text-2xl">Editar Mensagem</span>
                ) : (
                  <span className="font-bold text-2xl">Nova Mensagem</span>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-[100%] h-56 md:h-96  mb-12">
                <div className="flex flex-col w-full h-full ">
                  <ReactQuill
                    className="flex flex-col h-40  md:h-80"
                    id="editor"
                    onChange={(e: string) => setText(e)}
                    value={text}
                  />
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {editMensagem == null && (
                  <button
                    className="w-full flex justify-center items-center gap-2 rounded-md border border-transparent shadow-sm px-4
								py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={load}
                    onClick={() => handleSendMessage()}
                    type="button"
                  >
                    {load && <FaSpinner className="animate-spin" />}
                    Enviar
                  </button>
                )}
                {editMensagem != null && (
                  <button
                    className="w-full flex justify-center items-center gap-2 rounded-md border border-transparent shadow-sm px-4
								py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={load}
                    onClick={() => handleEditMessage()}
                    type="button"
                  >
                    {load && <FaSpinner className="animate-spin" />}
                    Salvar
                  </button>
                )}
                <button
                  className="mt-3 w-full flex justify-center items-center gap-2 rounded-md border border-gray-300 shadow-sm px-4
								py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  disabled={load}
                  onClick={() => setOpenModal(false)}
                  type="button"
                >
                  {load && <FaSpinner className="animate-spin" />}
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
